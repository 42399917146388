import axios from 'axios'
import { toast } from 'react-toastify';

const url = "https://iotops.memighty.com:8001/api";
// const url = "http://localhost:8001/api";

const MAX_RETRIES = 10; 

const config = () => ({
    headers: {
        Authorization: window.localStorage.getItem('token'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
});

export const LoginUser = async (info) => {
    try {
        const res = await axios.post(`https://iotops.memighty.com:8001/loginuser`, info)
        console.log(res);
        return { success: true, data: res.data }
    } catch (e) {
        console.log(e);
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getUsers = async (info) => {
    try {
        const res = await axios.get(`${url}/getUsers`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllRoles = async (info) => {
    try {
        const res = await axios.get(`${url}/getAllRoles`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const InsertsRoles = async (info) => {
    try {
        const res = await axios.post(`${url}/InsertsRoles`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateRoles = async (info, id) => {
    try {
        const res = await axios.put(`${url}/UpdateRoles/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getRolesById = async (id) => {
    try {
        const res = await axios.get(`${url}/getRolesById/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const updateUserPassword = async (id, info) => {
    try {
        const res = await axios.post(`${url}/updateUserPassword/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const updateUser = async (userId, info) => {
    try {
        const res = await axios.put(`${url}/updateUser/${userId}`, info, config())
        return { success: true, msg: "Successfully Added" }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const addUsersLogin = async (info) => {
    try {
        const res = await axios.post(`${url}/addUsersLogin`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const activeandinactive = async (info,id) => {
    try {
        const res = await axios.post(`${url}/activeandinactive/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}


export const UserPhotouploads = async (info,id) => {
    try {
        const res = await axios.post(`${url}/UserPhotouploads`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAccount = async (info) => {
    try {
        const res = await axios.get(`${url}/getAccount?status_filter=${info}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}
export const getArea = async (info) => {
    try {
        const res = await axios.get(`${url}/getArea?search=${info?.search}&CATEGORY0=${info?.CATEGORY0}&CATEGORY1=${info?.CATEGORY1}&CATEGORY2=${info?.CATEGORY2}&CATEGORY3=${info?.CATEGORY3}&CATEGORY4=${info?.CATEGORY4}&roleSerach=${info?.roleSerach}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getCircle = async (info) => {
    try {
        const res = await axios.get(`${url}/getCircle?search=${info?.search}&CATEGORY0=${info?.CATEGORY0}&CATEGORY1=${info?.CATEGORY1}&roleSerach=${info?.roleSerach}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getDivision = async (info) => {
    try {
        const res = await axios.get(`${url}/getDivision?search=${info?.search}&CATEGORY0=${info?.CATEGORY0}&CATEGORY1=${info?.CATEGORY1}&CATEGORY2=${info?.CATEGORY2}&roleSerach=${info?.roleSerach}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getGa = async (info) => {
    try {
        const res = await axios.get(`${url}/getGa?search=${info?.search}&CATEGORY0=${info?.CATEGORY0}&roleSerach=${info?.roleSerach}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getSubdevision = async (info) => {
    try {
        const res = await axios.get(`${url}/getSubdevision?search=${info?.search}&CATEGORY0=${info?.CATEGORY0}&CATEGORY1=${info?.CATEGORY1}&CATEGORY2=${info?.CATEGORY2}&CATEGORY3=${info?.CATEGORY3}&roleSerach=${info?.roleSerach}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const InsertAccount = async (info) => {
    try {
        const res = await axios.post(`${url}/InsertAccount`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateAccount = async (info, id) => {
    try {
        const res = await axios.put(`${url}/UpdateAccount/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const InsertGa = async (info) => {
    try {
        const res = await axios.post(`${url}/InsertGa`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateGa = async (info, id) => {
    try {
        const res = await axios.put(`${url}/UpdateGa/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const InsertCircle = async (info) => {
    try {
        const res = await axios.post(`${url}/InsertCircle`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateCircle = async (info, id) => {
    try {
        const res = await axios.put(`${url}/UpdateCircle/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const InsertDivision = async (info) => {
    try {
        const res = await axios.post(`${url}/InsertDivision`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateDivision = async (info, id) => {
    try {
        const res = await axios.put(`${url}/UpdateDivision/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const InsertSubDevision = async (info) => {
    try {
        const res = await axios.post(`${url}/InsertSubDevision`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateSubDivision = async (info, id) => {
    try {
        const res = await axios.put(`${url}/UpdateSubDivision/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const InsertArea = async (info) => {
    try {
        const res = await axios.post(`${url}/InsertArea`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateArea = async (info, id) => {
    try {
        const res = await axios.put(`${url}/UpdateArea/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAreaById = async (id) => {
    try {
        const res = await axios.get(`${url}/getAreaById/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getCircleById = async (id) => {
    try {
        const res = await axios.get(`${url}/getCircleById/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getDivisionById = async (id) => {
    try {
        const res = await axios.get(`${url}/getDivisionById/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getGaById = async (id) => {
    try {
        const res = await axios.get(`${url}/getGaById/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getSubdevisionById = async (id) => {
    try {
        const res = await axios.get(`${url}/getSubdevisionById/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getProfilNameByAccountId = async (info) => {
    try {
        const res = await axios.post(`${url}/getProfilNameByAccountId`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getCategoryNameByUniqId = async (info) => {
    try {
        const res = await axios.post(`${url}/getCategoryNameByUniqId`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const CheckUseConsumer = async (info) => {
    try {
        const res = await axios.post(`${url}/CheckUseConsumer`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Table_Get_All_Country = async (info) => {
    try {
        const res = await axios.get(`${url}/Table_Get_All_Country`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Table_Get_All_State_by_Country = async (id) => {
    try {
        const res = await axios.get(`${url}/Table_Get_All_State_by_Country/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Table_Get_All_City_by_State = async (id) => {
    try {
        const res = await axios.get(`${url}/Table_Get_All_City_by_State/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const rebrandingCompanyPhotos = async (info) => {
    try {
        const res = await axios.post(`${url}/rebrandingCompanyPhotos`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllModem = async (status) => {
    try {
        const res = await axios.get(`${url}/getAllModem?filterStatus=${status}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getModemFirmware = async (info) => {
    try {
        const res = await axios.get(`${url}/getModemFirmware`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getModemHardwareVersion = async (info) => {
    try {
        const res = await axios.get(`${url}/getModemHardwareVersion`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getModemMake = async (info) => {
    try {
        const res = await axios.get(`${url}/getModemMake`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getModemModelNumber = async (info) => {
    try {
        const res = await axios.get(`${url}/getModemModelNumber`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getModemProductCode = async (info) => {
    try {
        const res = await axios.get(`${url}/getModemProductCode`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getModemServerCommunication = async (info) => {
    try {
        const res = await axios.get(`${url}/getModemServerCommunication`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getModemType = async (info) => {
    try {
        const res = await axios.get(`${url}/getModemType`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const InsertFirmware = async (info) => {
    try {
        const res = await axios.post(`${url}/InsertFirmware`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateFirmware = async (info, id) => {
    try {
        const res = await axios.put(`${url}/UpdateFirmware/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Insertmodem_hardware_version = async (info) => {
    try {
        const res = await axios.post(`${url}/Insertmodem_hardware_version`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Updatemodem_hardware_version = async (info, id) => {
    try {
        const res = await axios.put(`${url}/Updatemodem_hardware_version/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Insertmodem_model_number = async (info) => {
    try {
        const res = await axios.post(`${url}/Insertmodem_model_number`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Updatemodem_model_number = async (info, id) => {
    try {
        const res = await axios.put(`${url}/Updatemodem_model_number/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Insertmodem_make = async (info) => {
    try {
        const res = await axios.post(`${url}/Insertmodem_make`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Updatemodem_make = async (info, id) => {
    try {
        const res = await axios.put(`${url}/Updatemodem_make/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}
export const Insertmodem_product_code = async (info) => {
    try {
        const res = await axios.post(`${url}/Insertmodem_product_code`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Updatemodem_product_code = async (info, id) => {
    try {
        const res = await axios.put(`${url}/Updatemodem_product_code/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Insertmodem_Sever_communication = async (info) => {
    try {
        const res = await axios.post(`${url}/Insertmodem_Sever_communication`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Updatemodem_Sever_communicatione = async (info, id) => {
    try {
        const res = await axios.put(`${url}/Updatemodem_Sever_communicatione/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Insertmodem_type = async (info) => {
    try {
        const res = await axios.post(`${url}/Insertmodem_type`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Updatemodem_type = async (info, id) => {
    try {
        const res = await axios.put(`${url}/Updatemodem_type/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Insertmodem_index = async (info) => {
    try {
        const res = await axios.post(`${url}/Insertmodem_index`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Updatemodem_index = async (info, id) => {
    try {
        const res = await axios.put(`${url}/Updatemodem_index/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllMeter = async (status,category_id) => {
    try {
        const res = await axios.get(`${url}/getAllMeter?filterStatus=${status}&category_id=${category_id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}


export const getmeter_category = async (info) => {
    try {
        const res = await axios.get(`${url}/getmeter_category`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getmeter_class = async (info) => {
    try {
        const res = await axios.get(`${url}/getmeter_class`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}
export const getmeter_firmware = async (info) => {
    try {
        const res = await axios.get(`${url}/getmeter_firmware`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getmeter_hardware_protocol = async (info) => {
    try {
        const res = await axios.get(`${url}/getmeter_hardware_protocol`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getmeter_make = async (info) => {
    try {
        const res = await axios.get(`${url}/getmeter_make`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getmeter_model_number = async (info) => {
    try {
        const res = await axios.get(`${url}/getmeter_model_number`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getmeter_software_protocol = async (info) => {
    try {
        const res = await axios.get(`${url}/getmeter_software_protocol`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getmeter_type = async (info) => {
    try {
        const res = await axios.get(`${url}/getmeter_type`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Insertmeter_category = async (info) => {
    try {
        const res = await axios.post(`${url}/Insertmeter_category`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Updatemeter_category = async (info, id) => {
    try {
        const res = await axios.put(`${url}/Updatemeter_category/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Insertmeter_class = async (info) => {
    try {
        const res = await axios.post(`${url}/Insertmeter_class`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Updatemmeter_class = async (info, id) => {
    try {
        const res = await axios.put(`${url}/Updatemmeter_class/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}
export const Insertmeter_make = async (info) => {
    try {
        const res = await axios.post(`${url}/Insertmeter_make`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Updatemeter_make = async (info, id) => {
    try {
        const res = await axios.put(`${url}/Updatemeter_make/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Insertmeter_model_number = async (info) => {
    try {
        const res = await axios.post(`${url}/Insertmeter_model_number`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Updatemeter_model_number = async (info, id) => {
    try {
        const res = await axios.put(`${url}/Updatemeter_model_number/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Insertmeter_firmware = async (info) => {
    try {
        const res = await axios.post(`${url}/Insertmeter_firmware`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Updatemeter_firmware = async (info, id) => {
    try {
        const res = await axios.put(`${url}/Updatemeter_firmware/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Insertmeter_hardware_protocol = async (info) => {
    try {
        const res = await axios.post(`${url}/Insertmeter_hardware_protocol`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Updatemeter_hardware_protocol = async (info, id) => {
    try {
        const res = await axios.put(`${url}/Updatemeter_hardware_protocol/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Insertmeter_software_protocol = async (info) => {
    try {
        const res = await axios.post(`${url}/Insertmeter_software_protocol`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Updatemeter_software_protocol = async (info, id) => {
    try {
        const res = await axios.put(`${url}/Updatemeter_software_protocol/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Insertmeter_type = async (info) => {
    try {
        const res = await axios.post(`${url}/Insertmeter_type`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Updatemmeter_type = async (info, id) => {
    try {
        const res = await axios.put(`${url}/Updatemmeter_type/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Insertmeter_index = async (info) => {
    try {
        const res = await axios.post(`${url}/Insertmeter_index`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Updatemeter_index = async (info, id) => {
    try {
        const res = await axios.put(`${url}/Updatemeter_index/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Updatemeter_index_attachments = async (id, info) => {
    try {
        const res = await axios.put(`${url}/Updatemeter_index_attachments/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getmeter_update_data = async (id, info) => {
    try {
        const res = await axios.get(`${url}/getmeter_update_data/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const MeterFileuploads = async (info) => {
    try {
        const res = await axios.post(`${url}/MeterFileuploads`, info, {
            headers: {
                Authorization: window.localStorage.getItem('token'),
                'Content-Type': 'multipart/form-data',
            },
        })
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const geturls3 = async (info) => {
    try {
        const res = await axios.post(`${url}/geturls3`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const InsertScriptdecodeId = async (info) => {
    try {
        const res = await axios.post(`${url}/InsertScriptdecodeId`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdatScriptdecodeId = async (info, id) => {
    try {
        const res = await axios.put(`${url}/UpdatScriptdecodeId/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getScriptdecodeId = async (info) => {
    try {
        const res = await axios.get(`${url}/getScriptdecodeId`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}


export const SyncScriptIdWiseTableColumn = async (info) => {
    try {
        const res = await axios.post(`${url}/SyncScriptIdWiseTableColumn`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getScriptCodeIdWiseColumnName = async (id) => {
    try {
        const res = await axios.get(`${url}/getScriptCodeIdWiseColumnName/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const gsmModemColumanWiseSerchData = async (info) => {
    try {
        const res = await axios.post(`${url}/gsmModemColumanWiseSerchData`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getGSMData = async (info, data) => {
    try {
        console.log(data);
        const res = await axios.get(`${url}/getGSMData?start=${info?.start}&end=${info.end}`, {
            method: 'GET',
            params: data,
            headers: {
                Authorization: window.localStorage.getItem('token'),
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }
        })
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const gsmModemFilterData = async (date, info) => {
    try {
        const res = await axios.post(`${url}/gsmModemFilterData?start=${date?.start}&end=${date.end}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const gsmModemSerchData = async (info) => {
    try {
        const res = await axios.post(`${url}/gsmModemSerchData`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getModemConsumerDetails = async (info) => {
    try {
        const res = await axios.post(`${url}/getModemConsumerDetails`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getModemConsumerDetailsByFilter = async (info) => {
    try {
        const res = await axios.post(`${url}/getModemConsumerDetailsByFilter`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}


export const getModemSearchTextConsumerDetails = async (info) => {
    try {
        const res = await axios.post(`${url}/getModemSearchTextConsumerDetails`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const DownloadModemConsumerDetailsByFilter = async (info) => {
    try {
        const res = await axios.post(`${url}/DownloadModemConsumerDetailsByFilter`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}


export const InsertModemDetails = async (info) => {
    try {
        const res = await axios.post(`${url}/InsertModemDetails`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateModemDetails = async (info, id) => {
    try {
        const res = await axios.post(`${url}/UpdateModemDetails/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getCountUseToConsumerInIMEI = async (id, update) => {
    try {
        const res = await axios.get(`${url}/getCountUseToConsumerInIMEI/${id}?update=${update?.update}&ID=${update?.ID}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getMeterByID = async (id) => {
    try {
        const res = await axios.get(`${url}/getMeterByID/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getModemByID = async (id) => {
    try {
        const res = await axios.get(`${url}/getModemByID/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const InsertGsmData = async (info) => {
    try {
        const res = await axios.post(`${url}/InsertGsmData`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const AddModemDataINGSM = async (id, info) => {
    try {
        const res = await axios.post(`${url}/AddModemDataINGSM/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const uploadsFiles = async (info, id) => {
    try {
        const res = await axios.post(`${url}/uploads/${id}`, info, { headers: { Authorization: window.localStorage.getItem('token')}})
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAttachmentBymeterid = async (id) => {
    try {
        const res = await axios.get(`${url}/getAttachmentBymeterid/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getMeterByMultipalID = async (info) => {
    try {
        const res = await axios.post(`${url}/getMeterByMultipalID`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const GetModemTypeWiseIMEI = async (id) => {
    try {
        const res = await axios.get(`${url}/GetModemTypeWiseIMEI/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const GetIMEIWiseModemData = async (id) => {
    try {
        const res = await axios.get(`${url}/GetIMEIWiseModemData/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getGSMDataById = async (id) => {
    try {
        const res = await axios.get(`${url}/getGSMDataById/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const geModemIdWiseColumnName = async (id) => {
    try {
        const res = await axios.post(`${url}/geModemIdWiseColumnName/${id}`, {}, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getHeatbeatStatusWiseCount = async (info) => {
    try {
        const res = await axios.post(`${url}/getHeatbeatStatusWiseCount`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getErrorbinAndDuplicatbinCountList = async (info, id) => {
    try {
        const res = await axios.post(`${url}/getErrorbinAndDuplicatbinCountList`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const GetTableNameWiseChartDataByDate = async (info, id) => {
    try {
        const res = await axios.post(`${url}/GetTableNameWiseChartDataByDate`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getRSSITotalGSM = async (info) => {
    try {
        const res = await axios.post(`${url}/getRSSITotalGSM`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const GetBuferCountInByStatus = async (id, tableName) => {
    try {
        const res = await axios.get(`${url}/GetBuferCountInByStatus/${id}/${tableName}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getGSMBufferInDeshboardAllCountByStatus = async (id, tableName) => {
    try {
        const res = await axios.get(`${url}/getGSMBufferInDeshboardAllCountByStatus/${id}/${tableName}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const LetestCountAndLastDateByTableName = async (info, id) => {
    try {
        const res = await axios.post(`${url}/LetestCountAndLastDateByTableName`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getStartAndEndDateByTable = async (info) => {
    try {
        const res = await axios.post(`${url}/getStartAndEndDateByTable`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const GetTableNameWiseChartRSSIDataByStartDateEndDate = async (info) => {
    try {
        const res = await axios.post(`${url}/GetTableNameWiseChartRSSIDataByStartDateEndDate`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const GetModemStatusDurationByTableAndDATE = async (info) => {
    try {
        const res = await axios.post(`${url}/GetModemStatusDurationByTableAndDATE`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getHeartbeatStatusByDate = async (info,id) => {
    try {
        const res = await axios.post(`${url}/getHeartbeatStatusByDate`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const GetTableNameWiseChartDataByStartDateEndDate = async (info) => {
    try {
        const res = await axios.post(`${url}/GetTableNameWiseChartDataByStartDateEndDate`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const GetModemStatusByTableAndDate = async (info) => {
    try {
        const res = await axios.post(`${url}/GetModemStatusByTableAndDate`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getSlavesData=async(info,data)=>{
    try {
const res=await axios.get(`${url}/getSlavesData?start=${info?.start}&end=${info.end}`,{
    method: 'GET',
    params: data,
    headers: {
        Authorization: window.localStorage.getItem('token'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
    }
})
return { success: true, data: res.data }

    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const InsertSlaveData = async (info) => {
    try {
        const res = await axios.post(`${url}/InsertSlaveData`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}


export const UpdateInGSMmodem = async (info, id) => {
    try {
        const res = await axios.put(`${url}/UpdateInGSMmodem/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const GetConsumerStatusHistoryByConsumerId = async (id) => {
    try {
        const res = await axios.get(`${url}/GetConsumerStatusHistoryByConsumerId/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const uploadsDeleteFile = async (info) => {
    try {
        const res = await axios.post(`${url}/uploadsDeleteFile`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Update_Gsm_consumer_Attachments = async (info, id) => {
    try {
        const res = await axios.put(`${url}/Update_Gsm_consumer_Attachments/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateConsumerStatusHistory = async (data,id) => {
    try {
        const res = await axios.post(`${url}/UpdateConsumerStatusHistory/${id}`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateInGSMModemDataAndModemTable = async (info, id) => {
    try {
        const res = await axios.post(`${url}/UpdateInGSMModemDataAndModemTable/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const ActivityLogMaintainOnFrontend = async (info,id) => {
    try {
        const res = await axios.post(`${url}/ActivityLogMaintainOnFrontend`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const ChangeStatusToPendding = async (id) => {
    try {
        const res = await axios.get(`${url}/ChangeStatusToPendding/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getSlaveDataById = async (id) => {
    try {
        const res = await axios.get(`${url}/getSlaveDataById/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}


export const UpdateInSlave = async (info, id) => {
    try {
        const res = await axios.put(`${url}/UpdateInSlave/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}


export const getHeardbeatData = async (info, data) => {
    try {
        const res = await axios.post(`${url}/getHeardbeatData?start=${info?.start}&end=${info.end}`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getConfigretionCMD_TYPEWisePendding_Data_buffer = async (info) => {
    try {
        const res = await axios.post(`${url}/getConfigretionCMD_TYPEWisePendding_Data_buffer`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getConfigretionCMD_TYPEWisePendding_Count_buffer = async (info) => {
    try {
        const res = await axios.post(`${url}/getConfigretionCMD_TYPEWisePendding_Count_buffer`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getGSMBufferDataByGSMID=async(info,id)=>{
    try {
const res=await axios.get(`${url}/getGSMBufferDataByGSMID/${id}?start=${info?.start}&end=${info.end}&tableName=${info.tableName}&info=${info?.info}`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const reportCreacteInTableName = async (info) => {
    try {
        const res = await axios.post(`${url}/reportCreacteInTableName`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getGSMBufferDataAllCountByStatus=async(tableName)=>{
    try {
const res=await axios.get(`${url}/getGSMBufferDataAllCountByStatus/${tableName}`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const RetryGSMBuffer=async(id,tableName)=>{
    try {
const res=await axios.post(`${url}/RetryGSMBuffer/${id}/${tableName}`,{},config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const cancalGSMBuffer=async(id,tableName)=>{
    try {
const res=await axios.post(`${url}/cancalGSMBuffer/${id}/${tableName}`,{},config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getSlaveScriptData=async(info)=>{
    try {
const res=await axios.post(`${url}/getSlaveScriptData?start=${info.start}&end=${info.end}`,{},config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const UpdateSlaveScript=async(info,id)=>{
    try {
const res=await axios.post(`${url}/UpdateSlaveScript/${id}`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const InsertSlaveScript=async(info)=>{
    try {
const res=await axios.post(`${url}/InsertSlaveScript`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getUsersList=async()=>{
    try {
const res=await axios.get(`${url}/getUsersList`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const onModemConfigByGSMConsumer=async(info)=>{
    try {
const res=await axios.post(`${url}/onModemConfigByGSMConsumer`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getConfigretionLetestDataByCmd_id=async(info)=>{
    try {
const res=await axios.post(`${url}/getConfigretionLetestDataByCmd_id`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getScriptCodeIdWiseConfigretionQuery=async(info)=>{
    try {
const res=await axios.post(`${url}/getScriptCodeIdWiseConfigretionQuery`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getConfigretionCMD_IDANDTYPEWiseData=async(info)=>{
    try {
const res=await axios.post(`${url}/getConfigretionCMD_IDANDTYPEWiseData`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getSlaveScriptByUserAndScriptidWise=async(info)=>{
    try {
const res=await axios.post(`${url}/getSlaveScriptByUserAndScriptidWise`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getDataConversionFunction=async(info)=>{
    try {
const res=await axios.get(`${url}/getDataConversionFunction`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const InsertModbusRegistration=async(info)=>{
    try {
const res=await axios.post(`${url}/InsertModbusRegistration`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const UpdateModbusRegistration=async(info,id)=>{
    try {
const res=await axios.post(`${url}/UpdateModbusRegistration/${id}`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getslave_all_register_profile=async(info)=>{
    try {
const res=await axios.post(`${url}/getslave_all_register_profile`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getgsm_modbus_function_code_index=async()=>{
    try {
const res=await axios.get(`${url}/getgsm_modbus_function_code_index`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getSlaveAllRegisterProfileBySlaveId=async(info)=>{
    try {
const res=await axios.post(`${url}/getSlaveAllRegisterProfileBySlaveId`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getCategoryParameters=async(info)=>{
    try {
const res=await axios.post(`${url}/getCategoryParameters`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const InsertCategoryParameters=async(info)=>{
    try {
const res=await axios.post(`${url}/InsertCategoryParameters`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const UpdateCategoryParameters=async(info,id)=>{
    try {
const res=await axios.put(`${url}/UpdateCategoryParameters/${id}`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const DeleteCategoryParameters=async(info,id)=>{
    try {
const res=await axios.post(`${url}/DeleteCategoryParameters/${id}`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getSlaveCategoryById=async(id)=>{
    try {
const res=await axios.get(`${url}/getSlaveCategoryById/${id}`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const UpdateColumnJsonInModbusRegistration=async(info,id)=>{
    try {
const res=await axios.post(`${url}/UpdateColumnJsonInModbusRegistration/${id}`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const InsertAccountWiseTableViewCoumnName=async(info)=>{
    try {
const res=await axios.post(`${url}/InsertAccountWiseTableViewCoumnName`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getAccountWiseTableViewCoumnName=async(info)=>{
    try {
const res=await axios.post(`${url}/getAccountWiseTableViewCoumnName`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const InsertAccountWiseModemTableViewCoumnName=async(info)=>{
    try {
const res=await axios.post(`${url}/InsertAccountWiseModemTableViewCoumnName`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getAccountWiseModemTableViewCoumnName=async(info)=>{
    try {
const res=await axios.post(`${url}/getAccountWiseModemTableViewCoumnName`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getModemDetailByID=async(id)=>{
    try {
const res=await axios.get(`${url}/getModemDetailByID/${id}`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const GetModemStatusByTable=async(info)=>{
    try {
const res=await axios.post(`${url}/GetModemStatusByTable`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const GetModemStatusDurationByTable=async(info)=>{
    try {
const res=await axios.post(`${url}/GetModemStatusDurationByTable`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getAllGroups = async (info) => {
    try {
        const res = await axios.post(`${url}/getAllGroups`,{}, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const createGroup = async (info) => {
    try {
        const res = await axios.post(`${url}/createGroup`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const updateGroup = async (info,id) => {
    try {
        const res = await axios.post(`${url}/updateGroup/${id}`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const updateUserGroupJunction = async (info,id) => {
    try {
        const res = await axios.post(`${url}/updateUserGroupJunction/${id}`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getUsersByGroups = async (id) => {
    try {
        const res = await axios.post(`${url}/getUsersByGroups/${id}`,{}, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}


export const InsertsTaskDetails = async (info) => {
    try {
        const res = await axios.post(`${url}/InsertsTaskDetails`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllTaskDetails = async (info) => {
    try {
        const res = await axios.post(`${url}/getAllTaskDetails`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllTaskDetailsByUniqueId = async (id) => {
    try {
        const res = await axios.get(`${url}/getAllTaskDetailsByUniqueId/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateTaskDetails = async (info, id) => {
    try {
        const res = await axios.put(`${url}/UpdateTaskDetails/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateTaskDetailesComentAndHistory = async (info, id) => {
    try {
        const res = await axios.post(`${url}/UpdateTaskDetailesComentAndHistory/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getTaskCommentAndHistoryById = async (info, id) => {
    try {
        const res = await axios.post(`${url}/getTaskCommentAndHistoryById`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllTaskAnalytics = async (info) => {
    try {
        const res = await axios.post(`${url}/getAllTaskAnalytics`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getFilteredTaskDetails = async (filter) => {
    console.log("Filters Util = ", filter)
    try {
        const res = await axios.post(`${url}/getFilteredTaskDetails`, filter, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getUsersListForTask = async () => {
    try {
        const res = await axios.get(`${url}/getUsersListForTask`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getTaskTag = async (status) => {
    try {
        const res = await axios.get(`${url}/getTaskTag?FilterStatus=${status}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const insertTaskTag = async (data) => {
    try {
        const res = await axios.post(`${url}/insertTaskTag`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const updateTaskTag = async (data) => {
    try {
        const res = await axios.post(`${url}/updateTaskTag`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getFilteredConsumerTaskDetails = async (filter) => {
    console.log("Consumer Filters Util = ", filter)
    try {
        const res = await axios.post(`${url}/getFilteredConsumerTaskDetails`, filter, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getMyStatusAnalytics = async (id) => {
    try {
        const res = await axios.post(`${url}/getMyStatusAnalytics`,id, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const saveFilterToDb = async (info) => {
    try {
        const res = await axios.post(`${url}/saveFilterToDb`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getFavoriteFilters = async (userId) => {
    console.log("userId", userId)
    try {
        const getFilters = await axios.get(`${url}/getFavoriteFilters/${userId}`, config())
        return { success: true, data: getFilters.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getGSMConsumerList = async (info) => {
    try {
        const res = await axios.post(`${url}/getGSMConsumerList`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Taskuploads = async (info) => {
    try {
        const res = await axios.post(`${url}/Taskuploads`, info,{headers: {
            Authorization: window.localStorage.getItem('token'),
        }})
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const TaskActivityLogMaintainOnFrontend = async (data) => {
    try {
        const res = await axios.post(`${url}/TaskActivityLogMaintainOnFrontend`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getTaskActivityLogs = async (data) => {
    try {
        const res = await axios.post(`${url}/getTaskActivityLogs`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateConsumerStatusInMaintanace = async (id) => {
    try {
        const res = await axios.get(`${url}/UpdateConsumerStatusInMaintanace/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateLorawanConsumerStatusInMaintanace = async (id) => {
    try {
        const res = await axios.get(`${url}/UpdateLorawanConsumerStatusInMaintanace/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllTaskDetailsByConsumerId = async (info) => {
    try {
        const res = await axios.post(`${url}/getAllTaskDetailsByConsumerId`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getPenddingqueueCommandInBuffer = async (info) => {
    try {
        const res = await axios.post(`${url}/getPenddingqueueCommandInBuffer`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const GetReportType = async (info) => {
    try {
        const res = await axios.post(`${url}/GetReportType`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Generate_Report_trigger = async (info) => {
    try {
        const res = await axios.post(`${url}/Generate_Report_trigger`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getReportBytypeName = async (UserId, roleId, type, info) => {
    try {
        const res = await axios.get(`${url}/getReportBytypeName/${UserId}?type=${type}&roleId=${roleId}&start=${info?.start}&end=${info?.end}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getReportDataInConsumerDetails = async (info) => {
    try {
        const res = await axios.post(`${url}/getReportDataInConsumerDetails`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}


export const FilterIdWiseNewFilterGenerate = async (info) => {
    try {
        const res = await axios.post(`${url}/FilterIdWiseNewFilterGenerate`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const SlaveConsumerFilterData = async (date,info) => {
    try {
        const res = await axios.post(`${url}/SlaveConsumerFilterData?start=${date?.start}&end=${date.end}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getSlaveGroups = async () => {
    try {
        const res = await axios.post(`${url}/getSlaveGroups`, {}, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const InsertSlaveGroup = async (info) => {
    try {
        const res = await axios.post(`${url}/InsertSlaveGroup`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateSlaveGroup = async (info,id) => {
    try {
        const res = await axios.put(`${url}/UpdateSlaveGroup/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const CheckModemStatus = async (id) => {
    try {
        const res = await axios.get(`${url}/CheckModemStatus/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getDueDateWiseAnalytics = async (info) => {
    try {
        const res = await axios.post(`${url}/getDueDateWiseAnalytics`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getTasAnalyticsGroupWise = async (info) => {
    try {
        const res = await axios.post(`${url}/getTasAnalyticsGroupWise`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const TaskReadStatusChange = async (info, id) => {
    try {
        const res = await axios.post(`${url}/TaskReadStatusChange/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllGroupsOfUser = async (id) => {
    try {
        const res = await axios.post(`${url}/getAllGroupsOfUser/${id}`, {}, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllUsersOfGroup = async (id) => {
    try {
        const res = await axios.post(`${url}/getAllUsersOfGroup/${id}`, {}, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getTaskAssignedToMeAnalytics = async (info) => {
    try {
        const res = await axios.post(`${url}/getTaskAssignedToMeAnalytics`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getSalveDailyConsumptions = async (info) => {
    try {
        const res = await axios.post(`${url}/getSalveDailyConsumptions`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getSalveHourlyConsumptions = async (info) => {
    try {
        const res = await axios.post(`${url}/getSalveHourlyConsumptions`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}


export const InsertGSMReportScheduler = async (info) => {
    try {
        const res = await axios.post(`${url}/InsertGSMReportScheduler`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateGSMReportScheduler = async (info,id) => {
    try {
        const res = await axios.post(`${url}/UpdateGSMReportScheduler/${id}`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getgsmReportSchedule = async (range,info) => {
    try {
        const res = await axios.post(`${url}/getgsmReportSchedule?start=${range?.start}&end=${range?.end}`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateApiScheduleReportStatus = async (info,id) => {
    try {
        const res = await axios.post(`${url}/UpdateApiScheduleReportStatus/${id}`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getReportScheduleCount = async (UserId, roleId,SCHEDULE_ID) => {
    try {
        const res = await axios.get(`${url}/getReportScheduleCount/${UserId}?roleId=${roleId}&SCHEDULE_ID=${SCHEDULE_ID}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getgsmReportActivityLogs = async (info) => {
    try {
        const res = await axios.post(`${url}/getgsmReportActivityLogs`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}


export const getNotificationList=async(info,data)=>{
    try {
const res=await axios.post(`${url}/getNotificationList?start=${info?.start}&end=${info.end}`,{},config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getnotification_receiver=async(info,data)=>{
    try {
const res=await axios.post(`${url}/getnotification_receiver?start=${info?.start}&end=${info.end}`,{},config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getalarm_template=async(info,data)=>{
    try {
const res=await axios.post(`${url}/getalarm_template?start=${info?.start}&end=${info.end}`,{},config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getAll_Active_alarm_template=async(info,data)=>{
    try {
const res=await axios.get(`${url}/getAll_Active_alarm_template`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}


export const get_notification_script = async (info) => {
    try {
        const res = await axios.post(`${url}/get_notification_script`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}


export const InsertNotificationReceiver = async (data) => {
    try {
        const res = await axios.post(`${url}/InsertNotificationReceiver`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}


export const UpdateNotificationReceiver = async (data,id) => {
    try {
        const res = await axios.post(`${url}/UpdateNotificationReceiver/${id}`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateGsmWebConfigDetails = async (data,id) => {
    try {
        const res = await axios.post(`${url}/UpdateGsmWebConfigDetails/${id}`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}


export const getConfigurationDashboardTab = async (status,tab_id,role) => {
    try {
        const response = await axios.get(`${url}/getConfigurationDashboardTab?status=${status}&role=${role}&tab_id=${tab_id}`, config());
        return { success: true, data: response.data };
    } catch (error) {
        console.error(`Error fetching getConfigurationDashboardTab ::`, error.message);
        throw error;
    }
};

export const insertConfigurationDashboardTab = async (info) => {
    try {
        const response = await axios.post(`${url}/insertConfigurationDashboardTab`, info, config());
        return { success: true, data: response.data };
    } catch (error) {
        console.error(`Error fetching insertConfigurationDashboardTab ::`, error.message);
        throw error;
    }
};

export const UpdateConfigurationDashboardTab = async (data) => {

    try {
        const response = await axios.post(`${url}/UpdateConfigurationDashboardTab/${data?.id}`, data, config());
        return { success: true, data: response.data };
    } catch (error) {
        console.error(`Error fetching UpdateConfigurationDashboardTab ::`, error.message);
        throw error;
    }
};


export const getConfigurationDashDashboardCard = async (tabID,info,card_id,role) => {
    try {
        const response = await axios.get(`${url}/getConfigurationDashDashboardCard/${tabID}?status=${info}&card_id=${card_id}&role=${role}`,config());
        return { success: true, data: response.data };
    } catch (error) {
        console.error(`Error fetching getConfigurationDashDashboardCard ::`, error.message);
        throw error;
    }
};

export const InsertConfigurationDashboardCard = async (info) => {
    try {
        const response = await axios.post(`${url}/InsertConfigurationDashboardCard`, info,config());
        return { success: true, data: response.data };
    } catch (error) {
        console.error(`Error fetching InsertConfigurationDashboardCard ::`, error.message);
        throw error;
    }
};

export const UpdateConfigurationDashboardCard = async (data) => {

    try {
        const response = await axios.post(`${url}/UpdateConfigurationDashboardCard/${data?.cardId}`, data, config());
        return { success: true, data: response.data };
    } catch (error) {
        console.error(`Error fetching UpdateConfigurationDashboardCard ::`, error.message);
        throw error;
    }
};


export const getSlaveDataByGroupId = async (info) => {
    try {
        const response = await axios.post(`${url}/getSlaveDataByGroupId`, info, config());
        return { success: true, data: response.data };
    } catch (error) {
        console.error(`Error fetching getSlaveGroups  ::`, error.message);
        throw error;
    }
};

export const IndividualCardRefresh = async (id) => {
    try {
        const response = await axios.get(`${url}/IndividualCardRefresh/${id}`, config());
        return { success: true, data: response.data };
    } catch (error) {
        console.error(`Error fetching getSlaveGroups  ::`, error.message);
        throw error;
    }
};

export const getTabsPermissionIds = async (id) => {
    try {
        const response = await axios.get(`${url}/getTabsPermissionIds/${id}`, config());
        return { success: true, data: response.data };
    } catch (error) {
        console.error(`Error fetching getSlaveGroups  ::`, error.message);
        throw error;
    }
};

export const getCardsPermissionIds = async (id) => {
    try {
        const response = await axios.get(`${url}/getCardsPermissionIds/${id}`, config());
        return { success: true, data: response.data };
    } catch (error) {
        console.error(`Error fetching getSlaveGroups  ::`, error.message);
        throw error;
    }
};

export const getTabsPermissionByUserId = async (id) => {
    try {
        const response = await axios.get(`${url}/getTabsPermissionByUserId/${id}`, config());
        return { success: true, data: response.data };
    } catch (error) {
        console.error(`Error fetching getSlaveGroups  ::`, error.message);
        throw error;
    }
};

export const getCardsPermissionByUserId = async (id) => {
    try {
        const response = await axios.get(`${url}/getCardsPermissionByUserId/${id}`, config());
        return { success: true, data: response.data };
    } catch (error) {
        console.error(`Error fetching getSlaveGroups  ::`, error.message);
        throw error;
    }
};

export const getDriveByMeterTypeId = async (info) => {
    console.log(info)
    try {
        const res = await axios.get(`${url}/getDriveByMeterTypeId/${info?.slave_type_id}/${info?.slave_category}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        console.error(`Error fetching getDriveByMeterTypeId  ::`, e.message);
        throw e;
    }
}

export const getTemperatureHumidityDataForChart = async (info) => {
    try {
        const res = await axios.post(`${url}/getTemperatureHumidityDataForChart`,info, config())
        return { success: true, data: res.data }
    } catch (error) {
        console.error(`Error fetching getTemperatureHumidityDataForChart  ::`, error.message);
        throw new Error(error);
    }
}

export const getModemIdWiseConfigretionQuery = async (info) => {
    try {
        const res = await axios.post(`${url}/getModemIdWiseConfigretionQuery`,info, config())
        return { success: true, data: res.data }
    } catch (error) {
        console.error(`Error fetching getTemperatureHumidityDataForChart  ::`, error.message);
        throw new Error(error);
    }
}