import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import $ from "jquery";
import moment from "moment";
import { geModemIdWiseColumnName, GetLoraBuferCountInByStatus, getEventCountList, getGSMBufferInDeshboardAllCountByStatus, getGSMDataById, getHeatbeatStatusWiseCount, getErrorbinAndDuplicatbinCountList, getScriptCodeIdWiseColumnName, GetTableNameWiseChartDataByDate, getSlaveDataById, GetModemStatusByTable, GetModemStatusByTableAndDate, GetModemStatusDurationByTable, GetModemStatusDurationByTableAndDATE, getProfilNameByAccountId, getRolesById, getRSSITotalGSM, getStartAndEndDateByTable, GetTableNameWiseChartDataByStartDateEndDate, GetTableNameWiseChartInRelayonTime, GetTableNameWiseChartInRelayonTimeByDate, GetTableNameWiseChartRSSIDataByStartDateEndDate, getTags, getTagsInGsm, LetestCountAndLastDateByTableName, UpdateTagsInGsm, getslave_all_register_profile, getSlaveAllRegisterProfileBySlaveId, getSalveDailyConsumptions, getSalveHourlyConsumptions, getTemperatureHumidityDataForChart } from "../../Common/Utils";
// import Configuration from "./GsmViewDeshboard/Configration";
// import ConsumerInfo from "./GsmViewDeshboard/ConsumerInfo";
// import ModemData from "./GsmViewDeshboard/ModemData";
// import MeterData from "./GsmViewDeshboard/MeterData";
// import Extra from "./GsmViewDeshboard/Extra";
// import Apexchart, { LongChart, SnrChart } from "./Apexchart";
import Chart from "react-apexcharts";
import { DatePicker, Modal, Space } from "antd";
import chroma from 'chroma-js';
// import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import swal from "sweetalert2";
import signal2 from '../../assets/img/secondsignal.png'
import signal3 from '../../assets/img/signal3.png'
import signal4 from '../../assets/img/signal4.png'
import signal5 from '../../assets/img/signal5.png'
// import Buffer from './GsmViewDeshboard/Buffer'
import { BootstrapTooltip } from "../../Common/common";
// import {
// 	withScriptjs,
// 	withGoogleMap,
// 	GoogleMap,
// 	Marker,
// 	InfoWindow
// } from "react-google-maps"
import mapicon from '../../assets/img/triangle-green.svg'
import mapRedicon from '../../assets/img/triangle-red.svg'
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Filler,
	Legend,
	Decimation, TimeScale
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import heartbeat from '../../assets/img/heartbeat.png'
import hourglass from '../../assets/img/hourglass.png'
import calendar from '../../assets/img/calendar.png'
import timetable from '../../assets/img/timetable.png'
import alert from '../../assets/img/alert.png'
import server from '../../assets/img/server.png'
// import ConsumerTask from "./GsmViewDeshboard/ConsumerTask/ConsumerTask";
import ChartLoaders from '../../Common/Loaders/ChartLoader'
// import Meter_GSM_dashboard from './Meter_GSM_dashboard'
import { X } from "react-feather";
// import Assets_management from "./GsmViewDeshboard/Assets_management";
import Lora from "../Slave/Slave";
// import Consumerinfo from "./LoraViewDeshboard/Consumerinfo";
// import Configration from "./LoraViewDeshboard/Configration";
// import Buffer from "./LoraViewDeshboard/Buffer";
import { toast } from "react-toastify";
import Consumerinfo from "./SlaveViewDeshboard/Consumerinfo";
import MeterData from "./SlaveViewDeshboard/MeterData";
import Configration from "./SlaveViewDeshboard/Configration";
import { SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue,Select } from "../../Common/ui/select";
// import Meter_Lora_Dashboard from "./Meter_Lora_Dashboard";


window.Swal = swal;

export default function Slave_deshboard(props) {
	const colors = ["#67b7dcd9", "#6794dcd9", "#6771dcd9", "#8067dcd9", "#a367dcd9", "#c767dcd9", "#dc67ced9", "#6771dcd9"]
	const colors15 = ["#67b7dcd9", "#6794dcd9", "#6771dcd9", "#8067dcd9", "#a367dcd9", "#c767dcd9", "#dc67ced9", "#6771dcd9", "#6794dcd9", "#67b7dcd9", "#8067dcd9", "#a367dcd9", "#c767dcd9"]
	const buttonArray = ["Dashboard", "Consumer Info", "Configuration", "Slave Data", "Extra", 'Tasks'];
	const buttonSubISLC = ["Wifi", "Zigbee", "Slave"];
	const MainbuttonArray = ["Dashboard", "G-SLC", "i-SLCs"];
	//Assets Management
	const btnColor = ['#4f5ece', '#f59e0b', '#fc544b', '#3abaf4', '#54ca68', '#191d21', '#7c5295', '#4f5ece']
	const subbuttonArray = ["Modem", "Slave"];
	const [btnText, setButtontext] = useState("Dashboard");
	const [btnMainText, setButtonMaintext] = useState("Dashboard");
	const [btnSubISLCText, setbtnSubISLCText] = useState("Wifi");
	const [btnSubText, setbtnSubText] = useState("Modem");
	const [GSMData, setGSMData] = useState({});
	const [InstantaneousChart, setInstantaneousChart] = useState({ total: [], date: [] })
	const [RelayOntimeChart, setRelayOntimeChart] = useState({ load1: [], load2: [], load3: [], date: [] })
	const [RelayOntimeChartBydate, setRelayOntimeChartBydate] = useState({ load1: [], load2: [], load3: [], date: [] })

	const [LoadsurvayChart, setLoadsurvayChart] = useState({ total: [], date: [] })
	const [timesyncChart, settimesyncChart] = useState({ total: [], date: [] })
	const [serverConChart, setserverConChart] = useState({ total: [], date: [] })
	const [DailyConsuptionChart, setDailyConsuptionChart] = useState({ total: [], date: [] })
	const [DailyChart, setDailyChart] = useState({ total: [], date: [] })
	const [MonthlyChart, setMonthlyChart] = useState({ total: [], date: [] })
	const [HardbeatChart, setHardbeatChart] = useState({ total: [], date: [] })
	const [RSSIChart, setRSSIChart] = useState({ total: [], date: [] })
	const [SNRChart, setSNRChart] = useState({ total: [], date: [] })
	const [SFChart, setSFChart] = useState({ total: [], date: [] })
	const [BAT_PERChart, setBAT_PERChart] = useState({ total: [], date: [] })

	const [Instantaneous15Chart, setInstantaneous15Chart] = useState({ total: [], date: [] })
	const [RSSIChartInDate, setRSSIChartInDate] = useState({ total: [], date: [] })
	const [ModemStatus, setModemStatus] = useState({ online_count: [], offline_count: [], date: [] })
	const [ModemStatusDuration, setModemStatusDuration] = useState({ online_count: [], offline_count: [], date: [] })
	const [ModemStatusChartInDate, setModemStatusChartInDate] = useState({ online_count: [], offline_count: [], date: [] })
	const [isModalVisibleModemStatus, setisModalVisibleModemStatus] = useState(false)
	const [ismodalOpenRelayonTime, setismodalOpenRelayonTime] = useState(false)
	const [ModemSTChartInDate, setModemSTChartInDate] = useState({ online_count: [], offline_count: [], date: [] })
	const [isModalVisibleModemST, setisModalVisibleModemST] = useState(false)
	const [BufferChart, setBufferChart] = useState([])
	const [loader, setLoader] = useState(false);
	const [InstantaneousCount, setInstantaneousCount] = useState({})
	const [MonthlyCount, setMonthlyCount] = useState({})
	const [DailyCount, setDailyCount] = useState({})
	const [LoadsurvayCount, setLoadsurvayCount] = useState({})
	const [TimeSyncCount, setTimeSyncCount] = useState({})
	const [otpCount, setotpCount] = useState({})
	const [UnlockCount, setUnlockCount] = useState({})
	const [DailyETChart, setDailyETChart] = useState({ total: [], date: [] })

	const [serverconCount, setserverconCount] = useState({})
	const [HardbeatCount, setHardbeatCount] = useState()
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [dates, setDates] = useState({ start: '', end: '' })
	const [ModelHeader, setModelHeader] = useState('')
	const [isModalTags, setisModalTags] = useState(false)
	const [tagsList, settagsList] = useState([])
	const [AllBufferStatus, setAllBufferStatus] = useState([])
	const [tagArray, setTagArray] = useState([])
	const [TagBtn, setTagBtn] = useState([])
	const [ChartLoader, setChartLoader] = useState(false)
	const [expireDate, setExpireDate] = useState({})
	const [isModalVisibleRssi, setisModalVisibleRssi] = useState(false)
	const [isModalVisibleMonth, setisModalVisibleMonth] = useState(false)
	const [btnProccess, setbtnProccess] = useState(false)
	const [EventCount, setEventCount] = useState({})
	const [BinCount, setBinCount] = useState({})
	const [HbdStatus, setHbdStatus] = useState({})
	const [permision, setpermision] = useState({});
	const [ColumnName, setColumnName] = useState({})
	const [rolesGSMConfig, setrolesGSMConfig] = useState('false')
	const [BufferModal, setBufferModal] = useState(false)
	const [InsDailyConsumptionChart, setInsDailyConsumptionChart] = useState({ total: [], date: [] })
	const [InsHourlyConsumptionChart, setInsHourlyConsumptionChart] = useState({ total: [], date: [] })
	const [isChartsLoading, setIsChartLoading] = useState({ Heartbeat: false, GSM_RSSI: true, Battery_Percentage: true, Hourly: false, Dailyload: false, Monthbill: false, instantaneous: false, buffer: false, SNR: true, SF: true })
	const params = useParams();
	const userInfo = JSON.parse(window.localStorage.getItem('user_info'))
	const [tempData, setTempData] = useState({ lable: [], data: [], isError: false, isLaoding: true })
	const [HumidityData, setHumidityData] = useState({ lable: [], data: [], isError: false, isLaoding: true })
	const [tempBarType, setTempBarType] = useState('line');
	const [humBarType, setHumBarType] = useState('line');
	const [isDataFetch, setisDataFetch] = useState(false)
	const [expandTempHumDData, setexpandTempHumData] = useState({ lable: [], data: [], isError: false, isLaoding: false })
	const [eventModalIsOpen, setEventModalIsOpen] = useState({ isOpen: false, Type: null, title: null })

	const onHandalebtn = (text) => {
		setButtontext(text);
		if (text !== 'Dashboard') {
			setbtnSubText('')
		} else if (text === 'Dashboard') {
			setbtnSubText('Modem')
		}
	};
	const eventModalIsOpenHandler = () => {
		setisDataFetch(false)
		setexpandTempHumData({ lable: [], data: [], isError: false, isLaoding: false })
		setEventModalIsOpen({ isOpen: false, Type: null, title: null, chartType: null })
	}
	const handleCancel = () => {
		setIsModalVisible(false);
		setInstantaneous15Chart({ total: [], date: [] })
		setChartLoader(false)
	};
	const handleCancelModalRSSI = () => {
		setisModalVisibleRssi(false);
		setRSSIChartInDate({ total: [], date: [] })
		setChartLoader(false)

	}
	const handleCancelMonth = () => {
		setisModalVisibleMonth(false);
		setChartLoader(false)
		setInstantaneous15Chart({ total: [], date: [] })
	}

	const ongetHeatbeatStatusWiseCount = async (tableName) => {
		const data = await getHeatbeatStatusWiseCount({ tableName: tableName })
		if (data.success == true) {
			setHbdStatus(data.data.data[0])
		} else {
			console.log('something want wrong')
		}
	}
	const findDataMeterColumn = (keys) => {
		return ColumnName?.instantaneous?.filter(into => into.Header == keys).length > 0 ? ColumnName?.instantaneous?.filter(into => into.Header == keys)[0] : {}
	}
	const columnWiseData = (value, column, header, MF) => {
		const col = ColumnName?.instantaneous?.filter(into => into.Header == column).length > 0 ? ColumnName.instantaneous.filter(into => into.Header == column)[0] : {}
		console.log(col, column)
		if (col?.Float == true) {
			if (col?.Division == true) {
				if (col?.MF == true && MF) {
					return (value * MF / col?.DivisionNumber).toFixed(col?.FloatPoint)
				} else {
					return (value / col?.DivisionNumber).toFixed(col?.FloatPoint)
				}
			} else {
				if (col?.MF == true && MF) {
					return (value * MF).toFixed(col?.FloatPoint)
				} else {
					return (value).toFixed(col?.FloatPoint)
				}
			}
		} else {
			if (col?.MF == true && MF) {
				if (col?.Division == true) {
					return (value * MF) / col?.DivisionNumber
				} else {
					return value * MF
				}
			} else {
				if (col?.Division == true) {
					if (col?.MF == true && MF) {
						return (value * MF) / col?.DivisionNumber
					} else {
						return value / col?.DivisionNumber
					}
				} else {
					return value
				}
			}
		}
	}

	const onHandaleRoles = async () => {
		setLoader(true);
		$(".loader").fadeOut("slow");
		const data = await getRolesById(userInfo?.role);
		if (data.success === true) {
			const obj = data.data.data[0];
			setpermision({ ...obj, roles_other_permission: JSON.parse(obj.roles_other_permission || '{}') });
			const permissionViewCard = JSON.parse(obj?.roles_other_permission || '{}')
			onDataInGsm(permissionViewCard, obj)
		} else {
			console.log('something want wrong')
			setLoader(false);

		}
	}

	const ongetScriptCodeIdWiseColumnName = async (slave_type, version_id, METER_TYPE) => {

		const slave_data = await getSlaveAllRegisterProfileBySlaveId({ version_id: version_id, slave_type: slave_type })
		if (slave_data.success === true) {
			setColumnName(JSON.parse(slave_data?.data?.data[0]?.column_json || '{}'))
		} else {
			console.log('something want wrong');
		}

	}

	const onConsumerUpdateDate = async () => {
		const data = await getSlaveDataById(props?.ConsumerDashDetails?.unique_id);
		if (data.success === true) {
			const objGSM = data.data.data[0];
			if (data.data.data.length > 0) {
				setGSMData({
					...objGSM,
					address: JSON.parse(objGSM.address || '{}'),
					unique_table_name: JSON.parse(objGSM.unique_table_name || '{}'),
				});
			} else {

			}
		} else {

		}
	}

	const onDataInGsm = async (permissionViewCard, permitObj) => {

		const data = await getSlaveDataById(props?.ConsumerDashDetails?.unique_id);
		if (data.success === true) {
			const objGSM = data.data.data[0];
			if (data.data.data.length > 0) {
				setGSMData({
					...objGSM,
					address: JSON.parse(objGSM.address || '{}'),
					unique_table_name: JSON.parse(objGSM.unique_table_name || '{}'),
				});
				const groupObj = {
					CATEGORY0: objGSM?.cat0_id,
					CATEGORY1: objGSM?.cat1_id,
					CATEGORY2: objGSM?.cat2_id,
					CATEGORY3: objGSM?.cat3_id,
					CATEGORY4: objGSM?.cat4_id,
					CATEGORY5: objGSM?.cat5_id
				}
				var flagDul = 0
				if (userInfo?.role != 'ROLE1650614500708') {


					Object.entries(groupObj).map(([key, value], id) => {
						if (value === '') {
							if (flagDul == 0) {
								const permit = JSON.parse(permitObj['roles_permission_' + (Object.entries(groupObj)[id - 1][0]?.toLowerCase())] || '{}')
								setrolesGSMConfig(permit[Object.entries(groupObj)[id - 1][0] + '_config']?.filter((item) => (item == Object.entries(groupObj)[id - 1][1])).length > 0 ? 'true' : 'false')
								flagDul = 1
							}
							return true
						}
					})
				}
				ongetScriptCodeIdWiseColumnName(objGSM?.slave_type, objGSM?.version_id)
				ongetProfilNameByAccountId(objGSM?.CAT0_ID)

				onLetestCountAndLastDateByTableName(JSON.parse(objGSM?.unique_table_name)?.instantaneous)

				onGetTableNameWiseChartDataByDate(JSON.parse(objGSM?.unique_table_name)?.instantaneous, objGSM?.SCRIPTDECODE_ID)
				

				ongetHeatbeatStatusWiseCount(JSON.parse(objGSM?.unique_table_name)?.HEARTBEAT)
				setLoader(false);
			} else {
				setLoader(false);

			}
		} else {
			console.log("something want wrong");
			setLoader(false);
		}
	};

	const ongetSalveDailyConsumptions = async (col,headers) => {
		setInsDailyConsumptionChart({ total: [], date: [], status: 0 })
		const data = await getSalveDailyConsumptions({ col: col, tableName: GSMData?.unique_table_name?.instantaneous, start_date: moment().add(-7, 'days').format('DD-MM-YYYY'), end_date: moment().format('DD-MM-YYYY') })
		if (data.success === true) {
			setInsDailyConsumptionChart({ total: Object.values(data?.data?.data || {}).map((item) => (Number(columnWiseData(item, headers)).toFixed(2))), date: Object.keys(data?.data?.data || {}).map((item) => (item)), status: 200 })
		} else {
			console.log('something want wrong')
			setInsDailyConsumptionChart({ total: [], date: [], status: 500 })
		}
	}

	const ongetSalveHourlyConsumptions = async (col,headers) => {
		setInsHourlyConsumptionChart({ total: [], date: [], status: 0 })
		const data = await getSalveHourlyConsumptions({ col: col, tableName: GSMData?.unique_table_name?.instantaneous, start_date: moment().add(-24, 'hours').format('DD-MM-YYYY HH:mm:ss'), end_date: moment().format('DD-MM-YYYY HH:mm:ss') })
		if (data.success === true) {
			var arrayofData = data?.data?.data?.map((item) => {
				return Number(columnWiseData(item.difference, headers)).toFixed(2)
			})
			setInsHourlyConsumptionChart({ total: arrayofData, date: data.data.data.map((item) => (moment.utc(item.DATE).format("DD-MM-YYYY hh:00:00 A"))), res_code: '200' })
		} else {
			console.log('something want wrong')
			setInsHourlyConsumptionChart({ total: [], date: [], status: 500 })
		}
	}

	useEffect(() => {
		if (Object.keys(ColumnName || {}).length) {
			
			if(GSMData?.slave_type=='SLID1726036813844'){
				onGetTemperatureDataForChart(GSMData?.unique_table_name?.instantaneous)
			
				onGetHumidityDataForChart(GSMData?.unique_table_name?.instantaneous)
			}else{
				ongetSalveDailyConsumptions(findDataMeterColumn('C11708343105812').Column,'C11708343105812')
				ongetSalveHourlyConsumptions(findDataMeterColumn('C11708343105812').Column,'C11708343105812')
			}
		}
	}, [ColumnName])


	const onRefreshBtn = () => {
		setbtnProccess(true)
		onGetTableNameWiseChartDataByDate(GSMData?.unique_table_name.instantaneous, GSMData?.SCRIPTDECODE_ID)
		onLetestCountAndLastDateByTableName(GSMData?.unique_table_name?.instantaneous)
		setbtnProccess(false)

	}




	const secondtohours = (totalSecond) => {
		// 		var duration = moment.duration(totalSecond, 'seconds');
		// var formatted = duration.format("HH.mm");

		return moment.utc(1000 * totalSecond).format('HH.mm')
	}

	const onGetTableNameWiseChartDataByDate = async (tableName, SCRIPTDECODE_ID) => {
		setInstantaneousChart({ total: [], date: [] })
		const data = await GetTableNameWiseChartDataByDate({ date: 7, tableName: tableName, scriptid: SCRIPTDECODE_ID, col: 'scr_ts_entry', fild: 'count(*)' })
		if (data.success === true) {
			setInstantaneousChart({ total: data.data.data.map((item) => (item.lengths)), date: data.data.data.map((item) => (moment(item.date, "YYYY-MM-DD").format("DD-MM-YYYY"))) })
		} else {
			console.log('something want wrong')
		}
	}
	const fetchDataForChart = async (tableName, eventType, setDataState, col, timeCol, startDate, endDate) => {

		setDataState({ lable: [], data: [], isError: false, isLaoding: true });

		try {
			// Prepare the payload for the API call
			const payload = { tableName, type: eventType, col: col, timeCol: timeCol };

			let asValue;

			switch (eventType) {
				case 'Temp':
					asValue = 'Temperature';
					break;
				case 'Hum':
					asValue = 'Humidity';
					break;
				default:
					asValue = null;
			}

			// Add startDate and endDate to payload only if they are provided
			if (startDate) {
				payload.startDate = startDate;
			}
			if (endDate) {
				payload.endDate = endDate;
			}

			// Make the API request
			const data = await getTemperatureHumidityDataForChart(payload);

			if (data?.success) {
				console.log(data);
				setDataState({ lable: data?.data?.data?.map((v) => v?.time) || [], data: data?.data?.data?.map((v) => v[`${asValue}`]) || [], isError: false, isLaoding: false });
			} else {
				console.log(`Something went wrong in fetchDataForChart (${eventType})`);
				setDataState({ lable: [], data: [], isError: true, isLaoding: false });
			}
		} catch (error) {
			console.log(`Something went wrong in fetchDataForChart (${eventType})`, error);
			setDataState({ lable: [], data: [], isError: true, isLaoding: false });
		}
	};

	const onGetTemperatureDataForChart = (tableName) => {
		fetchDataForChart(tableName, "Temp", setTempData, findDataMeterColumn('C31726039651940').Column, 'scr_ts_entry');
	};

	const onGetHumidityDataForChart = (tableName) => {
		fetchDataForChart(tableName, "Hum", setHumidityData, findDataMeterColumn('C31726039666885').Column, 'scr_ts_entry');
	};

	useEffect(() => {
		onHandaleRoles()
	}, [props?.ConsumerDashDetails]);

	const onLetestCountAndLastDateByTableName = async (tableName) => {
		const data = await LetestCountAndLastDateByTableName({ tableName: tableName, col: 'scr_ts_entry' })
		if (data.success === true) {
			setInstantaneousCount(data.data.data)
		} else {
			console.log('something want wrong')
		}
	}

	const Instantaneousstate = {
		options: {
			legend: {
				show: false,

			},

			plotOptions: {
				bar: {
					distributed: true,
					borderRadius: 5,
					borderRadiusApplication: 'end',
					dataLabels: {
						position: 'top',
						offsetY: 30,
						fontSize: '10px'
					},

				}
			},
			dataLabels: {
				offsetY: 10
			},
			xaxis: {
				floating: false,
				categories: InstantaneousChart.date.map((item) => ([item, `(${moment(item, 'DD-MM-YYYY').format('ddd')})`])),
				labels: {
					show: true,
					rotate: -90,
					rotateAlways: true,
					offsetX: 0,
					offsetY: 3,
				},
				// tickPlacement: 'on'
			},
			fill: {
				type: 'bar',
				gradient: {
					shade: 'dark',
					type: "vertical",
					shadeIntensity: 0.5,
					gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
					inverseColors: true,
					opacityFrom: 1,
					opacityTo: 1,
					stops: [0, 50, 100],
					colorStops: []
				}
			},
			chart: {
				toolbar: {
					show: false
				}
			},
			noData: {
				text: "Loading...",
				align: 'center',
				verticalAlign: 'middle',
				offsetX: 0,
				offsetY: 0,
				style: {
					color: "#000000",
					fontSize: '14px',
					fontFamily: "Helvetica"
				},
			},
			colors: colors
			// colors: [
			//     "#99ccdd", "#e9cbff", "#ffcfcb", "#99ccee", "#99bbee", "#aabbee", "#aaddee", "#a4daef"
			// ]
		},

		series: [
			{
				name: "Instantaneous",
				data: InstantaneousChart.total
			}
		]
	};


	const InsDailyConsumptionstate = {
		options: {
			legend: {
				show: false,

			},

			plotOptions: {
				bar: {
					distributed: true,
					borderRadius: 5,
					borderRadiusApplication: 'end',
					dataLabels: {
						position: 'top',
						offsetY: 30,
						fontSize: '10px'
					},

				}
			},
			dataLabels: {
				offsetY: 10
			},
			xaxis: {
				floating: false,
				categories: InsDailyConsumptionChart.date.map((item) => ([item, `(${moment(item, 'DD-MM-YYYY').format('ddd')})`])),
				labels: {
					show: true,
					rotate: -90,
					rotateAlways: true,
					offsetX: 0,
					offsetY: 3,
				},
				// tickPlacement: 'on'
			},
			fill: {
				type: 'bar',
				gradient: {
					shade: 'dark',
					type: "vertical",
					shadeIntensity: 0.5,
					gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
					inverseColors: true,
					opacityFrom: 1,
					opacityTo: 1,
					stops: [0, 50, 100],
					colorStops: []
				}
			},
			chart: {
				toolbar: {
					show: false
				}
			},
			noData: {
				text: "Loading...",
				align: 'center',
				verticalAlign: 'middle',
				offsetX: 0,
				offsetY: 0,
				style: {
					color: "#000000",
					fontSize: '14px',
					fontFamily: "Helvetica"
				},
			},
			colors: colors
			// colors: [
			//     "#99ccdd", "#e9cbff", "#ffcfcb", "#99ccee", "#99bbee", "#aabbee", "#aaddee", "#a4daef"
			// ]
		},

		series: [
			{
				name: "Consumption",
				data: InsDailyConsumptionChart.total
			}
		]
	};

	const InsHourlyConsumptionstate = {
		options: {
			legend: {
				show: false,

			},

			plotOptions: {
				bar: {
					distributed: true,
					borderRadius: 5,
					borderRadiusApplication: 'end',
					dataLabels: {
						position: 'top',
						offsetY: 30,
						fontSize: '10px'
					},

				}
			},
			dataLabels: {
				offsetY: 10
			},
			xaxis: {
				floating: false,
				categories: InsHourlyConsumptionChart.date.map((item) => ([moment(item, 'DD-MM-YYYY hh:mm:ss A').format('DD-MM-YYYY'),moment(item, 'DD-MM-YYYY hh:mm:ss A').format('hh:mm:ss A (ddd)')])),
				labels: {
					show: true,
					rotate: -90,
					rotateAlways: true,
					offsetX: 0,
					offsetY: -4,
				},
				// tickPlacement: 'on'
			},
			fill: {
				type: 'bar',
				gradient: {
					shade: 'dark',
					type: "vertical",
					shadeIntensity: 0.5,
					gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
					inverseColors: true,
					opacityFrom: 1,
					opacityTo: 1,
					stops: [0, 50, 100],
					colorStops: []
				}
			},
			chart: {
				toolbar: {
					show: false
				}
			},
			noData: {
				text: "Loading...",
				align: 'center',
				verticalAlign: 'middle',
				offsetX: 0,
				offsetY: 0,
				style: {
					color: "#000000",
					fontSize: '14px',
					fontFamily: "Helvetica"
				},
			},
			colors: colors
			// colors: [
			//     "#99ccdd", "#e9cbff", "#ffcfcb", "#99ccee", "#99bbee", "#aabbee", "#aaddee", "#a4daef"
			// ]
		},

		series: [
			{
				name: "Consumption",
				data: InsHourlyConsumptionChart.total
			}
		]
	};

	const Instantaneous15state = {
		options: {
			legend: {
				show: false,

			},

			plotOptions: {
				bar: {
					distributed: true,
					borderRadius: 5,
					borderRadiusApplication: 'end',
					dataLabels: {
						position: 'top',
						offsetY: 10,
						fontSize: '10px'
					},

				}
			},
			dataLabels: {
				offsetY: 10
			},
			xaxis: {
				floating: false,
				categories: Instantaneous15Chart.date.map((item) => ([item, `(${moment(item, 'DD-MM-YYYY').format('ddd')})`])),
				labels: {
					show: true,
					rotate: -90,
					rotateAlways: true,
					offsetX: 0,
					offsetY: 3,
				},
				// tickPlacement: 'on'
			},
			fill: {
				type: 'bar',
				gradient: {
					shade: 'dark',
					type: "vertical",
					shadeIntensity: 0.5,
					gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
					inverseColors: true,
					opacityFrom: 1,
					opacityTo: 1,
					stops: [0, 50, 100],
					colorStops: []
				}
			},
			chart: {
				toolbar: {
					show: true
				}
			},
			noData: {
				text: "Loading...",
				align: 'center',
				verticalAlign: 'middle',
				offsetX: 0,
				offsetY: 0,
				style: {
					color: "#000000",
					fontSize: '14px',
					fontFamily: "Helvetica"
				},
			},
			colors: colors
			// colors: [
			//     "#99ccdd", "#e9cbff", "#ffcfcb", "#99ccee", "#99bbee", "#aabbee", "#aaddee", "#a4daef"
			// ]
		},

		series: [
			{
				name: ModelHeader,
				data: Instantaneous15Chart.total
			}
		]
	};

	const getCommonChartOptions = (chartType, enableZoom = false, requireCtrl = false) => {
		const options = {
			responsive: true,
			maintainAspectRatio: false,
			scales: {
				x: {
					grid: {
						display: false
					},
					ticks: {
						maxRotation: 90,
						minRotation: 90,
						labelOffset: -10,
						font: {
							size: 12,
						}

					},
				},
				y: {
					grace: '15%',
					beginAtZero: true,
					grid: {
						color: 'rgba(0, 0, 0, 0.1)'
					}
				}
			},
			plugins: {
				legend: {
					display: false,
					position: 'top'
				},
				tooltip: {
					enabled: true,
					mode: 'index',
					intersect: false
				},
				title: {
					display: false,
				},
				datalabels: {
					display: false,
				}
			}
		};

		// Customize options based on chart type
		if (chartType === 'bar') {
			options.scales.x.stacked = true;
			options.scales.y.stacked = true;
		} else if (chartType === 'line') {
			options.elements = {
				line: {
					tension: 0.4
				}
			};
		}

		if (enableZoom) {
			options.plugins.zoom = {
				zoom: {
					wheel: {
						enabled: true,
						modifierKey: requireCtrl ? 'ctrl' : undefined
					},
					pinch: {
						enabled: true
					},
					mode: 'x',
				}
			};
		}

		return options;
	};
	// update :- make option for bar
	const barChartOptionsWithCtrl = getCommonChartOptions('bar', true, true);
	// update :- make option for line
	const lineChartOptionsNoCtrl = getCommonChartOptions('line', true, true);

	const barChartExpandOptionsWithCtrl = getCommonChartOptions('bar', true, false);
	// update :- make option for line
	const lineChartExpandOptionsNoCtrl = getCommonChartOptions('line', true, false);

	// update :- Datasets for temp
	const temperatureDatasets = {
		labels: tempData?.lable?.map((item) => ([moment.utc(item).format('DD-MM-YYYY'), moment.utc(item).format('HH:mm:ss'), ` (${moment.utc(item).format('ddd')})`])),
		datasets: [
			{
				label: "Temperature",
				data: tempData?.data,
				barPercentage: 1.0,
				backgroundColor: '#33b5e5',
				borderRadius: 5,
			}
		],
	};

	// update :-Datasets for humidity
	const humidityDatasets = {
		labels: HumidityData?.lable?.map((item) => ([moment.utc(item).format('DD-MM-YYYY'), moment.utc(item).format('HH:mm:ss'), ` (${moment.utc(item).format('ddd')})`])),
		datasets: [
			{
				label: "Humidity",
				data: HumidityData?.data,
				barPercentage: 1.0,
				backgroundColor: '#33b5e5',
				borderRadius: 5,
			}
		],
	};

	// update :-Datasets for humidity/temp expland
	const explandDatasets = {
		labels: expandTempHumDData?.lable?.map((item) => ([moment.utc(item).format('DD-MM-YYYY'), moment.utc(item).format('HH:mm:ss'), ` (${moment.utc(item).format('ddd')})`])),
		datasets: [
			{
				label: eventModalIsOpen?.title,
				data: expandTempHumDData?.data,
				barPercentage: 1.0,
				backgroundColor: '#33b5e5',
				borderRadius: 5,
			}
		],
	};

	const expandChartData = {
		labels: Instantaneous15Chart.date.map((item) => ([item, `(${moment(item, 'DD-MM-YYYY').format('ddd')})`])),
		datasets: [
			{
				label: ModelHeader,
				backgroundColor: ["rgba(103, 183, 220, 0.85)", "rgba(103, 148, 220, 0.85)", "rgba(103, 113, 220, 0.85)", "rgba(128, 103, 220, 0.85)", "rgba(163, 103, 220, 0.85)", "rgba(199, 103, 220, 0.85)", "rgba(220, 103, 206, 0.85)", "rgba(103, 113, 220, 0.85)"],
				data: Instantaneous15Chart.total,
			},
		],
	};
	const expandChartoptions = {
		responsive: true,
		maintainAspectRatio: false,

		plugins: {
			legend: {
				position: 'bottom',
				labels: {
					pointStyleWidth: 10,
					usePointStyle: true,
					pointStyle: 'rectRounded'
				}
			},
			zoom: {
				zoom: {
					wheel: {
						enabled: true // SET SCROOL ZOOM TO TRUE
					},
					mode: "x",
				},
				pan: {
					enabled: true,
					mode: "x",
				}
			},
			datalabels: {
				align: 'end',
				anchor: 'end',
				clamp: true,
				color: "black",
				padding: {
					top: 0,
					bottom: 0
				},
				font: function (context) {
					var w = context.chart.width;
					return {
						size: w < 512 ? 10 : 12,

					};
				},

			}
		},
		layout: {
			padding: {
				left: 50,
				right: 50,
				top: 20
			}
		},
	};



	const minutestohours = (totalMinutes) => {
		var minutes = Math.round(totalMinutes % 60);
		var hours = Math.round((totalMinutes - minutes) / 60);
		return hours + ':' + minutes;
	}

	const ModemStatusDurationStateByDate = {
		series: [{
			name: 'Online Duration',
			data: ModemStatusChartInDate.online_count
		}, {
			name: 'Offline Duration',
			data: ModemStatusChartInDate.offline_count
		}],
		options: {
			legend: {
				show: false,

			},
			chart: {
				type: 'bar',
				height: 350,
				stacked: true,
				toolbar: {
					show: false
				}
			},
			responsive: [{
				breakpoint: 480,
				options: {
					legend: {
						position: 'bottom',
						offsetX: -10,
						offsetY: 0
					}
				}
			}],
			plotOptions: {
				bar: {
					horizontal: false,
					borderRadius: 10,
					borderRadiusApplication: 'end',
					dataLabels: {
						total: {
							style: {
								fontSize: '13px',
								fontWeight: 900
							}
						}
					}
				},
			},
			xaxis: {
				categories: ModemStatusChartInDate?.date,
				labels: {
					show: true,
					rotate: -90,
					rotateAlways: true,
					offsetX: 0,
					offsetY: 3,
				}
			},
			tooltip: {
				shared: true,
				intersect: false,
				custom: function ({ series, seriesIndex, dataPointIndex, w }) {
					console.log(series, seriesIndex, dataPointIndex, w);
					return `<div style={{padding:'5px'}}>` + w.config.xaxis.categories[dataPointIndex] + `<br/>` + series[seriesIndex][dataPointIndex] + ' ' + 'Minutes' + `<br/>` + 'offline:' + series[seriesIndex + 1][dataPointIndex] + ' ' + 'Minutes' + `</div>`
				}
			},
			yaxis: [{
				allowDecimals: false,
				floating: false,
			}],

			fill: {
				opacity: 1
			},
			colors: ['#00e396', '#feb019']
		},
	}

	const ModemStatusStatebyDate = {
		series: [{
			name: 'Online',
			data: ModemSTChartInDate.online_count
		}, {
			name: 'Offline',
			data: ModemSTChartInDate.offline_count
		}],
		options: {
			legend: {
				show: false,

			},
			chart: {
				type: 'bar',
				height: 350,
				stacked: true,
				toolbar: {
					show: false
				}
			},
			responsive: [{
				breakpoint: 480,
				options: {
					legend: {
						position: 'bottom',
						offsetX: -10,
						offsetY: 0
					}
				}
			}],
			plotOptions: {
				bar: {
					horizontal: false,
					borderRadius: 10,
					borderRadiusApplication: 'end',
					dataLabels: {
						total: {
							style: {
								fontSize: '13px',
								fontWeight: 900
							}
						}
					},
					columnWidth: '60%',
				},
			},
			xaxis: {
				categories: ModemSTChartInDate?.date,
				labels: {
					show: true,
					rotate: -90,
					rotateAlways: true,
					offsetX: 0,
					offsetY: 3,
				}
			},
			yaxis: [{
				allowDecimals: false,
				floating: false,
				labels: {
					formatter: (value) => {
						return value.toFixed(0)
					},
				}
			}],
			fill: {
				opacity: 1
			},
			colors: ['#00e396', '#feb019']
		},
	}

	const customStyles = {
		menu: provided => ({
			...provided, width: "max-content",
			minWidth: "100%", zIndex: 9999
		}),
		option: (styles, { data, isDisabled, isFocused, isSelected }) => {
			const color = chroma(data.color);
			return {
				...styles,
				backgroundColor: isSelected
					? color
					: isFocused
						? color.alpha(0.1).css()
						: undefined,
				color: color,

				':active': {
					...styles[':active'],
					backgroundColor: !isDisabled
						? isSelected
							? color
							: color.alpha(0.3).css()
						: undefined,
				},
			};
		},
		multiValue: (provided, { data }) => {
			console.log("Colorr ====== ", data)
			const color = chroma(data.color);
			return {
				...provided,
				width: 'min-content',
				backgroundColor: color.alpha(0.2).css(),
			};
		},
		multiValueLabel: (styles, { data }) => ({
			...styles,
			color: data.color,
		}),
		multiValueRemove: (styles, { data }) => ({
			...styles,
			color: data.color,
			':hover': {
				backgroundColor: data.color,
				color: 'white',
			},
		}),


	}

	const colorBuffer = ["#191d21", "#54ca68", "#6777ef", "#3abaf4", "#fc544b", "#ffa426", '#e17ed5']

	const series = [
		{
			name: "Total",
			type: "column",
			data: BufferChart?.map((item) => (item?.total_length))
		}, {
			name: "Success",
			type: "column",
			data: BufferChart?.map((item) => (item?.success))
		}, {
			name: "Waiting for downlink",
			type: "column",
			data: BufferChart?.map((item) => (item?.wait_down))
		}, {
			name: "Cancelled by user",
			type: "column",
			data: BufferChart?.map((item) => (item?.cancel))
		},
		{
			name: "Timeout",
			type: "column",
			data: BufferChart?.map((item) => (item?.timeout))
		}, {
			name: "CRC Error",
			type: "column",
			data: BufferChart?.map((item) => (item?.crcerror))
		}, {
			name: "Waiting for Uplink",
			type: "column",
			data: BufferChart?.map((item) => (item?.wait_up))
		}

	];

	const seriesRelayTimeBydate = {
		labels: RelayOntimeChartBydate?.date,
		datasets: [
			{
				label: "Relay 1",
				data: RelayOntimeChartBydate?.load1,
				barPercentage: 1.0,
				backgroundColor: '#fc544b',
				borderRadius: 5,
			},
			{
				label: "Relay 2",
				data: RelayOntimeChartBydate?.load2,
				barPercentage: 1.0,
				backgroundColor: '#ffa426',
				borderRadius: 5,
			},
			{
				label: "Relay 3",
				data: RelayOntimeChartBydate?.load2,
				barPercentage: 1.0,
				backgroundColor: '#0000ff',
				borderRadius: 5,
			}
		],
	};

	const options = {
		chart: {
			id: "line"
		},

		plotOptions: {
			bar: {
				borderRadius: 5,
			}
		},
		dataLabels: {
			enabled: false
		},
		xaxis: {
			categories: BufferChart?.map((item) => (moment(item.date, "YYYY-MM-DD").format("DD-MM-YYYY"))),
			//will be displayed on the x-asis
		}
		,
		colors: colorBuffer
	};



	const sum = (arr) => {
		const result = arr.reduce((total, currentValue) => total = total + currentValue, 0);
		return result
	}

	const onOpenModal = (tableName, col, header) => {
		// onGetTableNameWiseChartDataBy15Date()
		ongetStartAndEndDateByTable(tableName, col)
		setModelHeader(header)
	}

	const onOpenTemHumModal = (tableName, col, chartType, header, Type) => {
		ongetStartAndEndDateByTable(tableName, col, true)
		setEventModalIsOpen({ isOpen: true, Type: Type, chartType: chartType, title: header })
	}
	const onOpenModalInRelayontime = async (tableName, col, header) => {
		ongetStartAndEndDateByTable(tableName, col, true)
		setismodalOpenRelayonTime(true)

	}
	const onLoadDataRelayontime = async () => {

	}
	const ongetStartAndEndDateByTable = async (tableName, col, modal) => {
		document.body.style.cursor = 'want'
		const data = await getStartAndEndDateByTable({ tableName: tableName, col: col })
		if (data.success === true) {
			setExpireDate(data.data)
			if (!modal) {
				setIsModalVisible(true)
			}
			document.body.style.cursor = 'default'
		} else {
			console.log("something want wrong")
			document.body.style.cursor = 'default'
		}
	}


	const ongetStartAndEndDateInRssiByTable = async (tableName, col) => {
		document.body.style.cursor = 'want'
		const data = await getStartAndEndDateByTable({ tableName: tableName, col: col })
		if (data.success === true) {
			setExpireDate(data.data)
			setisModalVisibleRssi(true)
			document.body.style.cursor = 'default'
		} else {
			console.log("something want wrong")
			document.body.style.cursor = 'default'
		}
	}
	const onChange = (date, dateString) => {
		setDates({ ...dates, start: dateString })
	};
	const onChanges = (date, dateString) => {
		setDates({ ...dates, end: dateString })
	};

	const onGetDateWiseChart = async () => {
		const x = new Date(moment(dates.start, 'DD-MM-YYYY').format('YYYY-MM-DD'));
		const y = new Date(moment(dates.end, 'DD-MM-YYYY').format('YYYY-MM-DD'));
		console.log(x > y, x, y);
		if (x > y) {
			return toast.warn('"Start Date" Must be Less than or Equal to "End Date"')
		}
		setChartLoader(true)
		var tableName = ''
		var col = ''
		if (ModelHeader == 'Alert') {
			tableName = GSMData.unique_table_name.APM_EVENT
			col = 'alert_event_ts'
		} else if (ModelHeader == 'Heartbeat') {
			tableName = GSMData.unique_table_name.HEARTBEAT
			col = 'entryts'
		} else if (ModelHeader == 'Timesync') {
			tableName = GSMData.unique_table_name.TIMESYNC_ALERT
			col = 'entryts'
		} else if (ModelHeader == 'Schedule Event') {
			tableName = GSMData.unique_table_name.SCHEDULE_EVENT
			col = 'sch_event_ts'
		} else if (ModelHeader == 'Hourly') {
			tableName = GSMData.unique_table_name.hourlydata
			col = 'entryts'
		} else if (ModelHeader == 'Instantaneous') {
			tableName = GSMData.unique_table_name.INSTANTANEOUS
			col = 'entryts'
		} else if (ModelHeader == 'Hour Wise Daily') {
			tableName = GSMData.unique_table_name.hourlydailydata
			col = 'entryts'
		} else if (ModelHeader == 'Instantaneous Backfill') {
			tableName = GSMData.unique_table_name.INSTANTANEOUS
			col = 'entryts'
		}
		if(ModelHeader==='Daily Consumption'){
			const data = await getSalveDailyConsumptions({ col: findDataMeterColumn('C11708343105812').Column, tableName: GSMData?.unique_table_name?.instantaneous, start_date: dates.start, end_date: dates.end })
			if (data.success === true) {
			setInstantaneous15Chart({ total: Object.values(data?.data?.data || {}).map((item) => (columnWiseData(item,'C11708343105812').toFixed(2))), date: Object.keys(data?.data?.data || {}).map((item) => (item)), status: 200 })
			setChartLoader(false)
			}else{
			setChartLoader(false)
			}
		}else if(ModelHeader==='Hourly Consumption'){
			const data= await getSalveHourlyConsumptions({ col: findDataMeterColumn('C11708343105812').Column, tableName: GSMData?.unique_table_name?.instantaneous, start_date: dates.start, end_date: dates.end })
			if (data.success === true) {
				var arrayofData = data?.data?.data?.map((item) => {
					return columnWiseData(item.difference, 'C11708343105812').toFixed(2)
				})
			setInstantaneous15Chart({ total: arrayofData, date: data.data.data.map((item) => (moment.utc(item.DATE).format("DD-MM-YYYY hh:00:00 A"))), res_code: '200' })
			setChartLoader(false)
			}else{
			setChartLoader(false)
			toast.error('Server Issue')
			}
		}else{
		const data = await GetTableNameWiseChartDataByStartDateEndDate({ startDate: ModelHeader == 'Monthbill' ? dates.start : moment(dates.start, "DD-MM-YYYY").format("YYYY-MM-DD"), endDate: ModelHeader == 'Monthbill' ? dates.end : moment(dates.end, "DD-MM-YYYY").format("YYYY-MM-DD"), fild: ModelHeader == 'Daily Consumtion History' ? 'EMT_PENRG_CUM_IMP_WH/' + (ColumnName?.DAILY_LOAD?.filter((item) => (item?.Column == 'EMT_PENRG_CUM_IMP_WH'))[0]?.DivisionNumber || 1000) : 'count(*)', col: col, scriptid: GSMData?.SCRIPTDECODE_ID, tableName: tableName, TYPE: ModelHeader == 'Monthbill' ? 'Monthly' : '' })
		if (data.success === true) {
			setInstantaneous15Chart({ total: data.data.data.map((item) => (item.lengths)), date: data.data.data.map((item) => (ModelHeader == 'Monthbill' ? moment(item.date, "YYYY-MM").format("MMMM-YYYY") : moment(item.date, "YYYY-MM-DD").format("DD-MM-YYYY"))) })
			setChartLoader(false)
		} else {
			console.log('something want wrong')
			setChartLoader(false)
			toast.error('Server Issue')

		}
	}
	}

	const onGetDateWiseInRSSIChart = async () => {
		const x = new Date(moment(dates.start, 'DD-MM-YYYY').format('YYYY-MM-DD'));
		const y = new Date(moment(dates.end, 'DD-MM-YYYY').format('YYYY-MM-DD'));
		console.log(x > y, x, y);
		if (x > y) {
			return toast.warn('"Start Date" Must be Less than or Equal to "End Date"')
		}
		setChartLoader(true)
		var tableName = GSMData.unique_table_name.HEARTBEAT
		var col = 'entryts'
		var fild = ''
		if (ModelHeader == 'LRSSI') {
			fild = 'entryts as ENTRYTS,lrssi as lengths'
		} else if (ModelHeader == 'SNR') {
			fild = 'entryts as ENTRYTS,lsnr as lengths'
		} else if (ModelHeader == 'SF') {
			fild = 'entryts as ENTRYTS,lsf as lengths'
		} else if (ModelHeader == 'Battery Percentage') {
			fild = 'entryts as ENTRYTS,BAT_PER as lengths'
		}
		const data = await GetTableNameWiseChartRSSIDataByStartDateEndDate({ startDate: moment(dates.start, "DD-MM-YYYY").format("YYYY-MM-DD"), endDate: moment(dates.end, "DD-MM-YYYY").format("YYYY-MM-DD"), fild: fild, col: col, scriptid: GSMData?.SCRIPTDECODE_ID, tableName: tableName })
		if (data.success === true) {
			setRSSIChartInDate({ total: data.data.data.map((item) => (item.lengths)), date: data.data.data.map((item) => ([moment.utc(item.ENTRYTS).format("DD-MM-YYYY hh:mm:ss A"), moment.utc(item.ENTRYTS).format("ddd")])) })
			setChartLoader(false)
		} else {
			console.log('something want wrong')
			setChartLoader(false)
		}
	}

	const onGetDateWiseInModemStatusChart = async () => {
		const x = new Date(moment(dates.start, 'DD-MM-YYYY').format('YYYY-MM-DD'));
		const y = new Date(moment(dates.end, 'DD-MM-YYYY').format('YYYY-MM-DD'));
		console.log(x > y, x, y);
		if (x > y) {
			return toast.warn('"Start Date" Must be Less than or Equal to "End Date"')
		}
		setChartLoader(true)
		var tableName = GSMData.unique_table_name.ONLINE_OFFLINE
		var col = 'entryts'
		const data = await GetModemStatusDurationByTableAndDATE({ startDate: moment(dates.start, "DD-MM-YYYY").format("YYYY-MM-DD"), endDate: moment(dates.end, "DD-MM-YYYY").format("YYYY-MM-DD"), tableName: tableName })
		if (data.success === true) {
			setModemStatusChartInDate({ online_count: data.data.data.map((item) => (item.online_total_duretion)), offline_count: data.data.data.map((item) => (item.offline_total_duretion)), date: data.data.data.map((item) => (moment(item.date, "YYYY-MM-DD").format("DD-MM-YYYY"))) })
			setChartLoader(false)
		} else {
			console.log('something want wrong')
			setChartLoader(false)
		}
	}

	const onGetModemStatusByTableAndDate = async () => {
		const x = new Date(moment(dates.start, 'DD-MM-YYYY').format('YYYY-MM-DD'));
		const y = new Date(moment(dates.end, 'DD-MM-YYYY').format('YYYY-MM-DD'));
		console.log(x > y, x, y);
		if (x > y) {
			return toast.warn('"Start Date" Must be Less than or Equal to "End Date"')
		}
		setChartLoader(true)
		var tableName = GSMData.unique_table_name.ONLINE_OFFLINE
		var col = 'entryts'
		const data = await GetModemStatusByTableAndDate({ startDate: moment(dates.start, "DD-MM-YYYY").format("YYYY-MM-DD"), endDate: moment(dates.end, "DD-MM-YYYY").format("YYYY-MM-DD"), tableName: tableName })
		if (data.success === true) {
			setModemSTChartInDate({ online_count: data.data.data.map((item) => (item.online_count)), offline_count: data.data.data.map((item) => (item.offline_count)), date: data.data.data.map((item) => (moment(item.date, "YYYY-MM-DD").format("DD-MM-YYYY"))) })
			setChartLoader(false)
		} else {
			console.log('something want wrong')
			setChartLoader(false)
		}
	}


	ChartJS.register(
		CategoryScale,
		LinearScale,
		PointElement,
		LineElement,
		Title,
		Tooltip,
		Filler,
		Legend,
		zoomPlugin, ChartDataLabels,
		Decimation, TimeScale
	);

	// let range_min = new Date(moment(RSSIChartInDate.date[RSSIChartInDate.date.length - 1], "DD-MM-YYYY hh:mm:ss A").format("MM-DD-YYYY hh:mm:ss A"))  //start date
	// range_min.setHours(range_min.getHours() - 10);

	// let range_max = new Date(moment(RSSIChartInDate.date[RSSIChartInDate.date.length - 1], "DD-MM-YYYY hh:mm:ss A").format("MM-DD-YYYY hh:mm:ss A")) //end date
	// range_max.setHours(range_max.getHours());

	// console.log("Datessss == ", moment(RSSIChartInDate.date[RSSIChartInDate.date.length - 1], "DD-MM-YYYY hh:mm:ss A").format("MM-DD-YYYY hh:mm:ss A"))
	// console.log("Datessss == ", range_min, range_max)

	const optionsRelayontime = {
		// parsing: false,
		responsive: true,
		maintainAspectRatio: false,
		scales: {
			x: {
				// type: 'time',
				ticks: {

					maxRotation: 90,
					minRotation: 90,
					font: {
						size: 10
					},

				}
			},

			y: {
				beginAtZero: true
			},
		},

		// parsing: false,
		plugins: {
			decimation: {
				enabled: true,
				algorithm: 'lttb',
				samples: 800,
				threshold: 800
			},
			legend: {
				position: 'bottom',
				labels: {
					pointStyleWidth: 10,
					usePointStyle: true,
					pointStyle: 'rectRounded'
				}
			},
			zoom: {
				zoom: {
					wheel: {
						enabled: true,
						modifierKey: 'ctrl' // SET SCROOL ZOOM TO TRUE
					},
					mode: "x",
				},
				pan: {
					enabled: true,
					mode: "x",
				}
			},
			datalabels: {
				backgroundColor: function (context) {
					return context.dataset.borderColor;
				},
				borderRadius: 4,
				align: 'end',
				anchor: 'end',
				clamp: true,
				color: "white",
				padding: {
					top: 0,
					bottom: 0
				},
				font: function (context) {
					var w = context.chart.width;
					return {
						size: w < 512 ? 10 : 12,

					};
				},

			}
		},
		elements: {
			line: {
				fill: true,
				tension: 0.3
			}
		},
		layout: {
			padding: {
				top: 20
			}
		},
	};

	const optionsRSSI = {
		// parsing: false,
		responsive: true,
		maintainAspectRatio: false,
		scales: {
			x: {
				// type: 'time',
				ticks: {

					maxRotation: 90,
					minRotation: 90,
					font: {
						size: 10
					},

				}
			},

			y: {
				beginAtZero: true
			},
		},

		// parsing: false,
		plugins: {
			decimation: {
				enabled: true,
				algorithm: 'lttb',
				samples: 800,
				threshold: 800
			},
			legend: {
				position: 'bottom',
				labels: {
					pointStyleWidth: 10,
					usePointStyle: true,
					pointStyle: 'rectRounded'
				}
			},
			zoom: {
				zoom: {
					wheel: {
						enabled: true,
						modifierKey: 'ctrl' // SET SCROOL ZOOM TO TRUE
					},
					mode: "x",
				},
				pan: {
					enabled: true,
					mode: "x",
				}
			},
			datalabels: {
				backgroundColor: function (context) {
					return context.dataset.borderColor;
				},
				borderRadius: 4,
				align: 'end',
				anchor: 'end',
				clamp: true,
				color: "white",
				padding: {
					top: 0,
					bottom: 0
				},
				font: function (context) {
					var w = context.chart.width;
					return {
						size: w < 512 ? 10 : 12,

					};
				},

			}
		},
		elements: {
			line: {
				fill: true,
				tension: 0.3
			}
		},
		layout: {
			padding: {
				top: 20
			}
		},
	};

	const dataRSSI = {
		labels: RSSIChartInDate.date,
		datasets: [
			{
				label: ModelHeader,
				data: RSSIChartInDate.total,
				fill: true,
				borderColor: 'rgb(53, 162, 235)',
				backgroundColor: (context) => {
					const ctx = context.chart.ctx;
					const gradient = ctx.createLinearGradient(0, 0, 0, 400);
					gradient.addColorStop(0, "rgba(53, 162, 235, 0.8)");
					gradient.addColorStop(1, "rgba(53, 162, 235, 0)");
					return gradient;
					// return pattern.draw('dot', gradient)
				},
				// backgroundColor: 'rgba(53, 162, 235, 0.5)',
			}
		]
	};
	var chartRef = useRef()
	const myChart = chartRef.current;
	const zoomIn = () => {
		if (myChart != undefined || myChart != null) {
			myChart?.zoom(1.2);
		}
	}
	const zoomOut = () => {
		if (myChart != undefined || myChart != null) {
			myChart?.zoom(0.8);
		}
	}

	const panLeft = () => {
		if (myChart != undefined || myChart != null) {
			myChart?.pan({ x: 400 }, undefined, 'default');
		}
	}
	const panRight = () => {
		if (myChart != undefined || myChart != null) {
			myChart?.pan({ x: -400 }, undefined, 'default');
		}
	}
	useEffect(() => {
		if (myChart != undefined) {
			console.log(myChart)
			myChart?.zoom(1.49);
			myChart?.pan({
				x: 500
			}, undefined, 'default');

			// myChart.pan({
			// 	x: - (Number.MAX_SAFE_INTEGER)
			// }, undefined, 'default');

		}

	}, [chartRef])
	const [NameObj, setNameObj] = useState({})
	const ongetProfilNameByAccountId = async (id) => {
		const data = await getProfilNameByAccountId({ ID: id })
		if (data.success === true) {
			setNameObj(JSON.parse(data?.data?.data[0]?.PROFILE_NAME || '{}'))
		} else {
			console.log('something want wrong')
		}
	}
	return (
		<>
			<Modal title="Buffer Data" visible={BufferModal} footer='' maskClosable={false} onCancel={() => (setBufferModal(false))} width={800}>
				{BufferChart.length > 0 && BufferChart.length > 0 ? <Chart options={options} type="bar" series={series} height={"300"} /> : <div className="tw-flex tw-justify-center tw-items-center tw-min-h-[315px]"><ChartLoaders /></div>}
			</Modal>
			<Modal title={'Relay On Time'} visible={ismodalOpenRelayonTime} maskClosable={false} footer='' onCancel={() => setismodalOpenRelayonTime(false)} width={1500}>
				<div className="d-flex tw-justify-between tw-items-center">
					<div className="d-flex tw-justify-center tw-items-end">
						<Space direction="horizontal">
							<div className="d-flex flex-column">Start In
								<DatePicker onChange={onChange} format='DD-MM-YYYY' name='start' disabledDate={(current) => {
									return (moment(expireDate.start_date).add(0, 'days') >= current) || (moment(expireDate.end_date).add(1, 'days') <= current);
								}} /></div>
							<div className="d-flex flex-column" >End In
								<DatePicker onChange={onChanges} name='end' format='DD-MM-YYYY' disabledDate={(current) => {
									return (moment(expireDate.start_date).add(0, 'days') >= current) || (moment(expireDate.end_date).add(1, 'days') <= current);
								}} /></div>
						</Space>
						<div>
							<a className={`btn btn-danger text-white ml-3 ${ChartLoader ? 'btn-progress disabled' : ''}`} onClick={() => (onLoadDataRelayontime())}>Submit</a>
						</div>

					</div>

					<div>
						<div>
							<div className="tw-mb-1">
								<small className='tw-text-red-300'><sup className="tw-text-sm !tw-top-0">*</sup> User can also do Zoom and Scroll using your mouse</small>
							</div>
							<div className="tw-flex tw-justify-end tw-items-center">
								<button className={`tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-p-1`} onClick={zoomIn}><BootstrapTooltip title="ZoomIn">
									<div className="tw-flex tw-items-center fa-lg">
										<i class="fas fa-search-plus" aria-hidden="true"></i>
									</div>
								</BootstrapTooltip>
								</button>
								<button className={`tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-p-1`} onClick={zoomOut}>
									<BootstrapTooltip title='ZoomOut'>
										<div className="tw-flex tw-items-center fa-lg">
											<i class="fas fa-search-minus" aria-hidden="true"></i>
										</div>
									</BootstrapTooltip>
								</button>

								<button className="tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-py-1 tw-px-2" onClick={panLeft}>
									<BootstrapTooltip title='Left scroll'>
										<div className="tw-flex tw-items-center fa-lg">
											<i class="fas fa-caret-left" aria-hidden="true"></i>
										</div>
									</BootstrapTooltip>
								</button>
								<button className="tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-py-1 tw-px-2" onClick={panRight}>
									<BootstrapTooltip title='Right scroll'>
										<div className="tw-flex tw-items-center fa-lg">
											<i class="fas fa-caret-right" aria-hidden="true"></i>
										</div>
									</BootstrapTooltip>
								</button>
							</div>

						</div>

					</div>

				</div>

				{/* {RelayOntimeChartBydate?.load1.length > 0 || RelayOntimeChartBydate?.load2.length > 0 || RelayOntimeChartBydate?.load3.length > 0 ? <div className=" tw-h-[560px] tw-max-h-screen tw-px-5"><Bar ref={chartRef} options={optionsRelayontime} data={seriesRelayTimeBydate} height={"500"} /> </div> : ''} */}

			</Modal>



			<Modal title={ModelHeader} visible={isModalVisible} footer='' maskClosable={false} onCancel={handleCancel} width={1000}>
				<div className="d-flex tw-justify-between tw-items-center">
					<div className="d-flex tw-justify-center tw-items-end">
						<Space direction="horizontal">
							<div className="d-flex flex-column">Start In
								<DatePicker onChange={onChange} format='DD-MM-YYYY' name='start' disabledDate={(current) => {
									return (moment(expireDate.start_date).add(-1, 'days') >= current) || (moment(expireDate.end_date).add(0, 'days') <= current);
								}} /></div>
							<div className="d-flex flex-column" >End In
								<DatePicker onChange={onChanges} name='end' format='DD-MM-YYYY' disabledDate={(current) => {
									return (moment(expireDate.start_date).add(-1, 'days') >= current) || (moment(expireDate.end_date).add(0, 'days') <= current);
								}} /></div>
						</Space>
						<a className={`btn btn-danger text-white ml-3 mt-3 ${ChartLoader ? 'btn-progress disabled' : ''}`} onClick={() => (onGetDateWiseChart())}>Submit</a>
					</div>
					{Instantaneous15Chart.total.length > 0 && Instantaneous15Chart.date.length > 0 && <div>
						<div>
							<div className="tw-mb-1">
								<small className='tw-text-red-300'><sup className="tw-text-sm !tw-top-0">*</sup> User can also do Zoom and Scroll using their mouse</small>
							</div>
							<div className="tw-flex tw-justify-end tw-items-center">
								<button className={`tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-p-1`} onClick={zoomIn}><BootstrapTooltip title="ZoomIn">
									<div className="tw-flex tw-items-center fa-lg">
										<i class="fas fa-search-plus" aria-hidden="true"></i>
									</div>
								</BootstrapTooltip>
								</button>
								<button className={`tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-p-1`} onClick={zoomOut}>
									<BootstrapTooltip title='ZoomOut'>
										<div className="tw-flex tw-items-center fa-lg">
											<i class="fas fa-search-minus" aria-hidden="true"></i>
										</div>
									</BootstrapTooltip>
								</button>

								<button className="tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-py-1 tw-px-2" onClick={panLeft}>
									<BootstrapTooltip title='Left scroll'>
										<div className="tw-flex tw-items-center fa-lg">
											<i class="fas fa-caret-left" aria-hidden="true"></i>
										</div>
									</BootstrapTooltip>
								</button>
								<button className="tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-py-1 tw-px-2" onClick={panRight}>
									<BootstrapTooltip title='Right scroll'>
										<div className="tw-flex tw-items-center fa-lg">
											<i class="fas fa-caret-right" aria-hidden="true"></i>
										</div>
									</BootstrapTooltip>
								</button>
							</div>

						</div>

					</div>}
				</div>
				{Instantaneous15Chart.total.length > 0 && Instantaneous15Chart.date.length > 0 ? <div className="tw-mt-2 tw-h-[560px] tw-max-h-screen tw-px-5"><Bar ref={chartRef} options={expandChartoptions} data={expandChartData} /> </div> : ''}
			</Modal>
			<Modal title={ModelHeader} visible={isModalVisibleRssi} maskClosable={false} footer='' onCancel={handleCancelModalRSSI} width={1500}>
				<div className="d-flex tw-justify-between tw-items-center">
					<div className="d-flex tw-justify-center tw-items-end">
						<Space direction="horizontal">
							<div className="d-flex flex-column">Start In
								<DatePicker onChange={onChange} format='DD-MM-YYYY' name='start' disabledDate={(current) => {
									return (moment(expireDate.start_date).add(-1, 'days') >= current) || (moment(expireDate.end_date).add(0, 'days') <= current);
								}} /></div>
							<div className="d-flex flex-column" >End In
								<DatePicker onChange={onChanges} name='end' format='DD-MM-YYYY' disabledDate={(current) => {
									return (moment(expireDate.start_date).add(-1, 'days') >= current) || (moment(expireDate.end_date).add(0, 'days') <= current);
								}} /></div>
						</Space>
						<div>
							<a className={`btn btn-danger text-white ml-3 ${ChartLoader ? 'btn-progress disabled' : ''}`} onClick={() => (onGetDateWiseInRSSIChart())}>Submit</a>
						</div>

					</div>

					{RSSIChartInDate.total.length > 0 && RSSIChartInDate.date.length > 0 && sum(RSSIChartInDate.total) > 0 ? <div>
						<div>
							<div className="tw-mb-1">
								<small className='tw-text-red-300'><sup className="tw-text-sm !tw-top-0">*</sup> User can also do Zoom and Scroll using your mouse</small>
							</div>
							<div className="tw-flex tw-justify-end tw-items-center">
								<button className={`tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-p-1`} onClick={zoomIn}><BootstrapTooltip title="ZoomIn">
									<div className="tw-flex tw-items-center fa-lg">
										<i class="fas fa-search-plus" aria-hidden="true"></i>
									</div>
								</BootstrapTooltip>
								</button>
								<button className={`tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-p-1`} onClick={zoomOut}>
									<BootstrapTooltip title='ZoomOut'>
										<div className="tw-flex tw-items-center fa-lg">
											<i class="fas fa-search-minus" aria-hidden="true"></i>
										</div>
									</BootstrapTooltip>
								</button>

								<button className="tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-py-1 tw-px-2" onClick={panLeft}>
									<BootstrapTooltip title='Left scroll'>
										<div className="tw-flex tw-items-center fa-lg">
											<i class="fas fa-caret-left" aria-hidden="true"></i>
										</div>
									</BootstrapTooltip>
								</button>
								<button className="tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-py-1 tw-px-2" onClick={panRight}>
									<BootstrapTooltip title='Right scroll'>
										<div className="tw-flex tw-items-center fa-lg">
											<i class="fas fa-caret-right" aria-hidden="true"></i>
										</div>
									</BootstrapTooltip>
								</button>
							</div>

						</div>

					</div> : ''}

				</div>

				{/* <div className=" tw-h-[560px] tw-max-h-screen tw-px-5"><Line ref={chartRef} options={optionsRSSI} data={dataRSSI} /> </div> */}

			</Modal>
			<Modal title={ModelHeader} visible={isModalVisibleMonth} maskClosable={false} footer='' onCancel={handleCancelMonth} width={1000}>
				<div className="d-flex">
					<Space direction="horizontal">
						<div className="d-flex flex-column">Start In
							<DatePicker onChange={onChange} format='MM-YYYY' name='start' picker="month" disabledDate={(current) => current.isAfter(moment())} /></div>
						<div className="d-flex flex-column" >End In
							<DatePicker onChange={onChanges} name='end' format='MM-YYYY' picker="month" disabledDate={(current) => current.isAfter(moment())} /></div>
					</Space>
					<a className={`btn btn-danger text-white ml-3 mt-3 ${ChartLoader ? 'btn-progress disabled' : ''}`} onClick={onGetDateWiseChart}>Submit</a>
				</div>
				{Instantaneous15Chart.total.length > 0 && Instantaneous15Chart.date.length > 0 && sum(Instantaneous15Chart.total) > 0 ? <Chart options={Instantaneous15state.options} type="bar" series={Instantaneous15state.series}
					height={"500"} /> : ''}
			</Modal>
			<Modal title={ModelHeader} visible={isModalVisibleModemStatus} footer='' maskClosable={false} onCancel={() => setisModalVisibleModemStatus(false)} width={1000}>
				<div className="d-flex">
					<Space direction="horizontal">
						<div className="d-flex flex-column">Start In
							<DatePicker onChange={onChange} format='DD-MM-YYYY' name='start'
								disabledDate={(current) => current.isAfter(moment())} /></div>
						<div className="d-flex flex-column" >End In
							<DatePicker onChange={onChanges} name='end' format='DD-MM-YYYY' disabledDate={(current) => current.isAfter(moment())} /></div>
					</Space>
					<a className={`btn btn-danger text-white ml-3 mt-3 ${ChartLoader ? 'btn-progress disabled' : ''}`} onClick={() => (onGetDateWiseInModemStatusChart())}>Submit</a>
				</div>
				{ModemStatusChartInDate.online_count.length > 0 && ModemStatusChartInDate.offline_count.length > 0 && ModemStatusChartInDate.date.length > 0 ? <Chart options={ModemStatusDurationStateByDate.options} type="bar" series={ModemStatusDurationStateByDate.series}
					height={"500"} /> : ''}
			</Modal>
			<Modal title={ModelHeader} visible={isModalVisibleModemST} footer='' maskClosable={false} onCancel={() => setisModalVisibleModemST(false)} width={1000}>
				<div className="d-flex">
					<Space direction="horizontal">
						<div className="d-flex flex-column">Start In
							<DatePicker onChange={onChange} format='DD-MM-YYYY' name='start'
								disabledDate={(current) => {
									return (moment(expireDate.start_date).add(-1, 'days') >= current) || (moment(expireDate.end_date).add(0, 'days') <= current);
								}} /></div>
						<div className="d-flex flex-column" >End In
							<DatePicker onChange={onChanges} name='end' format='DD-MM-YYYY' disabledDate={(current) => {
								return (moment(expireDate.start_date).add(-1, 'days') >= current) || (moment(expireDate.end_date).add(0, 'days') <= current);
							}} /></div>
					</Space>
					<a className={`btn btn-danger text-white ml-3 mt-3 ${ChartLoader ? 'btn-progress disabled' : ''}`} onClick={() => (onGetModemStatusByTableAndDate())}>Submit</a>

				</div>

				{ModemSTChartInDate.online_count.length > 0 && ModemSTChartInDate.offline_count.length > 0 && ModemSTChartInDate.date.length > 0 ? <Chart options={ModemStatusStatebyDate.options} type="bar" series={ModemStatusStatebyDate.series}
					height={"500"} /> : ''}
			</Modal>
			<Modal title={eventModalIsOpen?.title} visible={eventModalIsOpen?.isOpen} footer=''
			 maskClosable={(e) => eventModalIsOpenHandler({ isOpen: false, Type: null, title: null, chartType: null })} 
			 onCancel={() => eventModalIsOpenHandler({ isOpen: false, Type: null, title: null, chartType: null })} width={800}>
				<div className="d-flex">
					<Space direction="horizontal">
						<div className="d-flex flex-column">Start In
							<DatePicker onChange={onChange} format='DD-MM-YYYY' name='start'
							disabledDate={(current)=>{return current.isAfter(moment())}}
							/></div>
						<div className="d-flex flex-column" >End In
							<DatePicker onChange={onChanges} name='end' format='DD-MM-YYYY' disabledDate={(current)=>{return current.isAfter(moment())}}/></div>
					</Space>
					<a className={`btn btn-danger text-white ml-3 mt-3 ${eventModalIsOpen?.isLaoding ? 'btn-progress disabled' : ''}`} onClick={() => {
						setisDataFetch(true)
						fetchDataForChart(GSMData.unique_table_name.instantaneous, "Temp", setexpandTempHumData, eventModalIsOpen.Type === 'Temp' ? findDataMeterColumn('C31726039651940').Column : findDataMeterColumn('C31726039666885').Column, 'scr_ts_entry', dates.start, dates.end)}}>Submit</a>

				</div>
				{ expandTempHumDData.isLaoding ? <div className="tw-flex tw-justify-center tw-items-center tw-min-h-[315px]"><ChartLoaders /></div> :
					expandTempHumDData?.isError ? <div className="tw-flex tw-justify-center tw-items-center !tw-font-bold !tw-text-2xl  tw-min-h-[550px]">Data Not Found</div> : expandTempHumDData.data.length > 0 && expandTempHumDData.lable.length > 0 ? <div className="tw-min-h-[550px]" >
						{
							eventModalIsOpen.chartType === 'line' ? <Line options={lineChartExpandOptionsNoCtrl} data={explandDatasets} /> : <Bar options={barChartExpandOptionsWithCtrl} data={explandDatasets} />
						}

					</div> : isDataFetch ?  <div className="tw-flex tw-justify-center tw-items-center !tw-font-bold !tw-text-2xl tw-min-h-[550px]">Data Not Found</div> : ""}
			</Modal>
			{loader ? (
				<div className="main-content">
					<div class="loader"></div>
				</div>
			) : (
				""
			)}

			<div class="col-12 d-flex justify-content-between align-items-center pb-3 px-0">
				<nav aria-label="breadcrumb">
					<ol class="breadcrumb p-0 mb-0">

						<li class="breadcrumb-item active !tw-text-[#1890ff]" aria-current="page" onClick={() => props?.setOpenConsumerDash(false)}>

							<i class="fas fa-list"></i> Slave{" "}

						</li>
						<li class="breadcrumb-item active" aria-current="page">
							View Slave Consumer{" "}
						</li>
						<li class="breadcrumb-item active" aria-current="page">
							{GSMData?.unique_id}
						</li>
					</ol>
				</nav>
			</div>
			<div class="row">
				<div class="col-xl-3 col-lg-5 col-md-5 col-sm-6 col-xs-12">
					<div class="card">
						<div class="card-header" style={{ padding: "10px 15px" }}>
							<h4>Consumer Info</h4>
							<div class="card-header-action">
								{GSMData?.latitude && GSMData?.longitude ? <a href={GSMData?.latitude && GSMData?.longitude ? `https://map.google.com/?q=${GSMData?.latitude},${GSMData?.longitude}` : '#'} target={'_blank'} class={`btn btn-success btn-sm text-white font-5 mr-2 ${GSMData?.latitude && GSMData?.longitude ? '' : ''}`}>
									<BootstrapTooltip title='Directions'>
										<a >
											<i class="fas fa-directions"></i>
										</a></BootstrapTooltip></a> : <BootstrapTooltip title='lat-long is not available'>
									<span className='mr-2'><a class={`btn btn-success btn-sm text-white font-5  ${GSMData?.latitude && GSMData?.longitude ? '' : 'tw-opacity-30'}`}>
										<a >
											<i class="fas fa-directions"></i>
										</a></a></span></BootstrapTooltip>}
								{GSMData?.latitude && GSMData?.longitude ? <a href={GSMData?.latitude && GSMData?.longitude ? `/gsm-map-view?${GSMData?.UNIQUE_ID}` : '#'} target={'_blank'}
									class={`btn btn-danger btn-sm text-white font-5 ${GSMData?.latitude && GSMData?.longitude ? '' : ''}`}
								><BootstrapTooltip title='Location'>
										<a>
											<i class="fas fa-map-marker-alt"></i>
										</a>
									</BootstrapTooltip></a> : <BootstrapTooltip title='lat-long is not available'><span><a class={`btn btn-danger btn-sm text-white font-5 ${GSMData?.latitude && GSMData?.longitude ? '' : 'tw-opacity-30'}`}
									>
										<a
										>
											<i class="fas fa-map-marker-alt"></i>
										</a>
									</a></span></BootstrapTooltip>}
							</div>
						</div>
						<div class="card-body" style={{ paddingTop: "0px", paddingBottom: "10px", paddingLeft: "18px", height: '110px' }}>
							<div class="row font-10">
								<div class="col-lg-12">

									<b>Slave Address : </b>
									{GSMData?.slave_address}
									<br />
									<b>Consumer Unique ID : </b>
									{GSMData?.unique_id}
									<br />
									<b>Consumer Status : </b>
									{GSMData?.slave_status == 1 ? (
										<>
											<span>
												<svg
													class="svg-inline--fa fa-circle col-green mr-1"
													aria-hidden="true"
													focusable="false"
													data-prefix="fas"
													data-icon="circle"
													role="img"
													xmlns="http://www.w3.org/2000/svg"
													viewBox="0 0 512 512"
													data-fa-i2svg=""
												>
													<path
														fill="currentColor"
														d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256z"
													></path>
												</svg>
												Active
											</span>
											<br />
										</>
									) : GSMData?.slave_status == -1 ? (
										<>
											<span>
												<svg
													class="svg-inline--fa fa-circle col-yellow mr-1"
													aria-hidden="true"
													focusable="false"
													data-prefix="fas"
													data-icon="circle"
													role="img"
													xmlns="http://www.w3.org/2000/svg"
													viewBox="0 0 512 512"
													data-fa-i2svg=""
												>
													<path
														fill="currentColor"
														d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256z"
													></path>
												</svg>
												Pending Configration
											</span>
											<br />
										</>
									) : GSMData?.slave_status == 0 ? (
										<>
											<span>
												<svg
													class="svg-inline--fa fa-circle col-red mr-1"
													aria-hidden="true"
													focusable="false"
													data-prefix="fas"
													data-icon="circle"
													role="img"
													xmlns="http://www.w3.org/2000/svg"
													viewBox="0 0 512 512"
													data-fa-i2svg=""
												>
													<path
														fill="currentColor"
														d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256z"
													></path>
												</svg>
												InActive
											</span>
											<br />
										</>
									) : GSMData?.slave_status == 2 ? (
										<>
											<span>
												<svg
													class="svg-inline--fa fa-circle col-red mr-1"
													aria-hidden="true"
													focusable="false"
													data-prefix="fas"
													data-icon="circle"
													role="img"
													xmlns="http://www.w3.org/2000/svg"
													viewBox="0 0 512 512"
													data-fa-i2svg=""
												>
													<path
														fill="#3abaf4"
														d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256z"
													></path>
												</svg>
												Under Maintenance
											</span>
											<br />
										</>
									) : (
										<>
											<span>
												<svg
													class="svg-inline--fa fa-circle col-red mr-1"
													aria-hidden="true"
													focusable="false"
													data-prefix="fas"
													data-icon="circle"
													role="img"
													xmlns="http://www.w3.org/2000/svg"
													viewBox="0 0 512 512"
													data-fa-i2svg=""
												>
													<path
														fill=""
														d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256z"
													></path>
												</svg>
												Stand By
											</span>
											<br />
										</>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* <div class="col-xl-2 col-lg-3 col-md-3 col-sm-6 col-xs-12">
						<div class="card">
							<div class="card-header" style={{ padding: "10px 15px" }}>
								<h4>Group Info</h4>
							</div>
							<div class="card-body" style={{ paddingTop: "0px", paddingBottom: "10px", paddingLeft: "18px", height: '154px' }}>
								<div class="row font-10">
									<div class="col-lg-12">
										<b>Account : </b>
										{GSMData?.ACCOUNT_NAME || "-"}
										<br />
										<b>{NameObj?.CATEGORY1||"CATEGORY1"} : </b>
										{GSMData?.GA_NAME || "-"}
										<br />
										<b>{NameObj?.CATEGORY2||"CATEGORY2"} : </b> {GSMData?.CIRCLE_NAME || "-"} <br />
										<b>{NameObj?.CATEGORY3||"CATEGORY3"} : </b> {GSMData?.DIVISION_NAME || "-"} <br />
										<b>{NameObj?.CATEGORY4||"CATEGORY4"} : </b> {GSMData?.SUB_DIVISION_NAME || "-"} <br />
										<b>{NameObj?.CATEGORY5||"CATEGORY5"} : </b> {GSMData?.AREA_NAME || "-"}
									</div>
								</div>
							</div>
						</div>
					</div> */}

				<div class="col-xl-2 col-lg-4 col-md-4 col-sm-6 col-xs-12">
					<div class="card">
						<div class="card-header" style={{ padding: "10px 15px" }}>
							<h4>Slave Info</h4>
						</div>
						<div class="card-body" style={{ paddingTop: "0px", paddingBottom: "10px", paddingLeft: "18px", height: '110px' }}>
							<div class=" font-10">
								<b>Slave Serial No : </b> {GSMData?.slave_serial_no}
								<br />
								<b>Slave Profile : </b> {GSMData?.slave_profile_name}

							</div>
						</div>
					</div>
				</div>

				{/* <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12">
						<div class="card">
							<div class="card-header" style={{ padding: "10px 15px" }}>
								<h4>Tags</h4>
								<div class="card-header-action">
									<BootstrapTooltip title='Tags'>
										<div>
											<a
												onClick={onOpenTagsModal}
												class="btn btn-primary text-white"
												data-toggle="tooltip"
												data-placement="Top"
												title=""
												data-original-title="Tags"
											>
												<i class="fa fa-tag"></i>
											</a>
										</div>
									</BootstrapTooltip>
								</div>
							</div>
							<div class="card-body" style={{ paddingTop: "0px", paddingBottom: "10px", paddingLeft: "18px", height: '150px' }}>
								<div class="row" style={{ marginTop: "10px" }}>
									<div class="col-lg-12">
										{TagBtn.length > 0 && TagBtn?.map((item) => (
											<div class={`btn btn-icon icon-left btn-sm  ml-1`} style={{ backgroundColor: item?.TAG_COLOR, color: "white" }}><i class="fa fa-tag fa-lg" style={{ marginRight: '4px' }}></i>{item.TAG_NAME}</div>
										))}
									</div>
								</div>
							</div>
						</div>
					</div> */}
			</div>

			<div className="row">
				<div className="col-12">
					<div className="card pb-2">
						<div className="col-12" style={{ marginTop: "15px" }}>
							<div className="buttons">

								{buttonArray.map((item, id) => (
									item == 'Configuration' ? rolesGSMConfig === 'true' || userInfo?.role == 'ROLE1650614500708' ? <button
										style={{
											backgroundColor: buttonArray.findIndex((data) => data === btnText) === id ? chroma(btnColor[id]).alpha(0.4).css() : '',
											color: buttonArray.findIndex((data) => data === btnText) === id ? chroma(btnColor[id]).darken(1.5).css() : btnColor[id],
											borderBottom: `1px solid ${btnColor[id]}`,
											boxShadow: buttonArray.findIndex((data) => data === btnText) === id ? `0 2px 8px -3px ${btnColor[id]}` : '',
										}}
										className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${buttonArray.findIndex((data) => data === btnText) === id ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}
										onClick={() => onHandalebtn(item)}>
										{item}
									</button> : '' : <button
										style={{
											backgroundColor: buttonArray.findIndex((data) => data === btnText) === id ? chroma(btnColor[id]).alpha(0.4).css() : '',
											color: buttonArray.findIndex((data) => data === btnText) === id ? chroma(btnColor[id]).darken(1.5).css() : btnColor[id],
											borderBottom: `1px solid ${btnColor[id]}`,
											boxShadow: buttonArray.findIndex((data) => data === btnText) === id ? `0 2px 8px -3px ${btnColor[id]}` : '',
										}}
										className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${buttonArray.findIndex((data) => data === btnText) === id ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}

										onClick={() => onHandalebtn(item)}>
										{item}
									</button>
								))}
								<div class="float-right text-right">
									<Link onClick={() => props?.setOpenConsumerDash(false)} class="btn btn-info text-white align-left">
										Back
									</Link>
								</div>
							</div>
						</div>

						{btnText === "Slave Data" && <MeterData GSMdata={GSMData} permision={permision} onConsumerUpdateDate={onConsumerUpdateDate} ConsumerDashDetails={props?.ModemDetails} />}
						{btnText === "Configuration" && <Configration GSMdata={GSMData} ConsumerDashDetails={props?.ModemDetails} />}

					</div>
				</div>
				{btnText === "Consumer Info" && <Consumerinfo GSMdata={GSMData} permision={permision} onConsumerUpdateDate={onConsumerUpdateDate} ConsumerDashDetails={props?.ModemDetails} />}
				{/* {btnText === "Buffer" && <Buffer GSMdata={GSMData} />} */}
				{btnText === "Dashboard" && (
					<>
						<div className="row">
							<div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12">
								<div class="card card-success">
									<div class="card-header" style={{ padding: "10px 15px" }}>
										<h4><span className="mr-2">Instantaneous</span><BootstrapTooltip title='Find Data In instantaneous table scr_ts_entry Wise' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
										<div class="card-header-action">
											<a class="btn btn-info text-white" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => onOpenModal(GSMData.unique_table_name.instantaneous, 'scr_ts_entry', 'Instantaneous')}>Expand</a>
										</div>
									</div>
									<div class="card-body" style={{ padding: "0px" }}>
										{InstantaneousChart.date.length > 0 && InstantaneousChart.total.length > 0 ? <Chart options={Instantaneousstate.options} type="bar" series={Instantaneousstate.series} height={"300"} /> : InstantaneousChart?.res_code == 503 ? <h4 className="tw-flex tw-justify-center tw-items-center tw-min-h-[300px]">Table Not Found</h4> : <div className="tw-flex tw-justify-center tw-items-center tw-min-h-[315px]"><ChartLoaders /></div>}
									</div>
									<p class="text-right" style={{ marginRight: "20px", lineHeight: "10px" }}>Bar chart</p>
								</div>

							</div>
							{GSMData?.slave_type=='SLID1726036813844'?<>
							<div class="col-xl-8 col-lg-6 col-md-12 col-sm-12 col-xs-12">
								<div class="card card-success">
									<div class="card-header" style={{ padding: "10px 15px" }}>
										<h4><span className="mr-2">Temperature</span><BootstrapTooltip title='Find Data In instantaneous table scr_ts_entry Wise' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
										<div class="card-header-action tw-flex tw-items-center tw-gap-2">
											<Select value={tempBarType} onValueChange={(e) => setTempBarType(e)} >
												<SelectTrigger className="tw-w-[120px]">
													<SelectValue placeholder="Select a fruit" />
												</SelectTrigger>
												<SelectContent>
													<SelectGroup>
														<SelectItem value="bar">Bar</SelectItem>
														<SelectItem value="line">Line</SelectItem>
													</SelectGroup>
												</SelectContent>
											</Select>
											<a class="btn btn-info text-white" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => onOpenTemHumModal(GSMData.unique_table_name.instantaneous, 'scr_ts_entry', tempBarType, 'Temperature', 'Temp')}>Expand</a>
										</div>
									</div>
									<div class="card-body" style={{ padding: "0px" }}>

										{tempData.isLaoding ? <div className="tw-flex tw-justify-center tw-items-center tw-min-h-[315px]"><ChartLoaders /></div> : tempData.isError ? <div className="tw-flex tw-justify-center tw-items-center tw-min-h-[315px]">Data Not Found</div> : tempData.data.length > 0 && tempData.lable.length > 0 ? <div className="tw-min-h-[315px]" >
											{
												tempBarType === 'line' ? <Line options={lineChartOptionsNoCtrl} data={temperatureDatasets} /> : <Bar options={barChartOptionsWithCtrl} data={temperatureDatasets} />
											}
										</div> : <div className="tw-flex tw-justify-center tw-items-center !tw-font-bold !tw-text-2xl tw-min-h-[315px]">Data Not Found</div>}
									</div>
									<p class="text-right" style={{ marginRight: "20px", lineHeight: "10px" }}>Bar chart</p>
								</div>

							</div>

							<div class="col-xl-8 col-lg-6 col-md-12 col-sm-12 col-xs-12">
								<div class="card card-success">
									<div class="card-header" style={{ padding: "10px 15px" }}>
										<h4><span className="mr-2">Humidity</span><BootstrapTooltip title='Find Data In instantaneous table scr_ts_entry Wise' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
										<div class="card-header-action tw-flex tw-items-center tw-gap-2">
											<Select value={humBarType} onValueChange={(e) => setHumBarType(e)} >
												<SelectTrigger className="tw-w-[120px]">
													<SelectValue placeholder="Select a fruit" />
												</SelectTrigger>
												<SelectContent>
													<SelectGroup>
														<SelectItem value="bar">Bar</SelectItem>
														<SelectItem value="line">Line</SelectItem>
													</SelectGroup>
												</SelectContent>
											</Select>
											<a class="btn btn-info text-white" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => onOpenTemHumModal(GSMData.unique_table_name.instantaneous, 'scr_ts_entry', humBarType, 'Humidity', 'Hum')}>Expand</a>
										</div>
									</div>
									<div class="card-body" style={{ padding: "0px" }}>

										{HumidityData.isLaoding ? <div className="tw-flex tw-justify-center tw-items-center tw-min-h-[315px]"><ChartLoaders /></div> : HumidityData.isError ? <div className="tw-flex tw-justify-center tw-items-center tw-min-h-[315px]">Data Not Found</div> : HumidityData.data.length > 0 && HumidityData.lable.length > 0 ? <div className="tw-min-h-[315px]" >
											{
												humBarType === 'line' ? <Line options={lineChartOptionsNoCtrl} data={humidityDatasets} /> : <Bar options={barChartOptionsWithCtrl} data={humidityDatasets} />
											}

										</div> : <div className="tw-flex tw-justify-center tw-items-center !tw-font-bold !tw-text-2xl tw-min-h-[315px]">Data Not Found</div>}
									</div>
									<p class="text-right" style={{ marginRight: "20px", lineHeight: "10px" }}>Bar chart</p>
								</div>

							</div>
							</>:<>
							<div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12">
								<div class="card card-success">
									<div class="card-header" style={{ padding: "10px 15px" }}>
										<h4><span className="mr-2">Daily Consumption</span><BootstrapTooltip title='Find Data In instantaneous table scr_ts_entry Wise' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
										<div class="card-header-action">
											<a class="btn btn-info text-white" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => onOpenModal(GSMData.unique_table_name.instantaneous, 'scr_ts_entry', 'Daily Consumption')}>Expand</a>
										</div>
									</div>
									<div class="card-body" style={{ padding: "0px" }}>
										{InsDailyConsumptionChart.date.length > 0 && InsDailyConsumptionChart.total.length > 0 ? <Chart options={InsDailyConsumptionstate.options} type="bar" series={InsDailyConsumptionstate.series} height={"300"} /> : InsDailyConsumptionChart?.res_code == 503 ? <h4 className="tw-flex tw-justify-center tw-items-center tw-min-h-[300px]">Table Not Found</h4> : <div className="tw-flex tw-justify-center tw-items-center tw-min-h-[315px]"><ChartLoaders /></div>}
									</div>
									<p class="text-right" style={{ marginRight: "20px", lineHeight: "10px" }}>Bar chart</p>
								</div>

							</div>
							<div class="col-xl-8 col-lg-6 col-md-12 col-sm-12 col-xs-12">
							<div class="card card-success">
								<div class="card-header" style={{ padding: "10px 15px" }}>
									<h4><span className="mr-2">Hourly Consumption</span><BootstrapTooltip title='Find Data In instantaneous table scr_ts_entry Wise' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
									<div class="card-header-action">
										<a class="btn btn-info text-white" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => onOpenModal(GSMData.unique_table_name.instantaneous, 'scr_ts_entry', 'Hourly Consumption')}>Expand</a>
									</div>
								</div>
								<div class="card-body" style={{ padding: "0px" }}>
								{InsHourlyConsumptionChart.date.length > 0 && InsHourlyConsumptionChart.total.length > 0 ? <Chart options={InsHourlyConsumptionstate.options} type="bar" series={InsHourlyConsumptionstate.series} height={"300"} /> : InsHourlyConsumptionChart.res_code == 503 ? <div className="tw-flex tw-justify-center tw-items-center tw-min-h-[315px]">Table Not Found</div> : <div className="tw-flex tw-justify-center tw-items-center tw-min-h-[315px]"><ChartLoaders /></div>}
								</div>
								<p class="text-right" style={{ marginRight: "20px", lineHeight: "10px" }}>Bar chart</p>
							</div>

						</div>
						</>}
							
						</div>

						<div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-3 row-cols-xl-5">
							<div class="col">
								<div class="card card-success">
									<div class="card-statistic-4">
										<div class="align-items-center justify-content-between">
											<div class="row ">
												<div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pr-0">
													<div class="card-content">
														<h5 class="font-14">Instantaneous Count</h5>
														<h2 class="font-18 col-blue">{InstantaneousCount?.total || 0}</h2>
													</div>

												</div>
												<div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 pl-0">
													<div class="banner-img">
														<img
															src={hourglass}
															alt=""
															style={{ paddingTop: "15px",height:"45px" }}
														/>
													</div>
												</div>
											</div>
											<div class="row">
												<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pr-0">
													<p class="mb-0 font-10">
														<BootstrapTooltip placement="top" title={InstantaneousCount?.ENTRYTS ? moment.utc(InstantaneousCount?.ENTRYTS).format('DD-MM-YYYY hh:mm:ss A') : 0}><span class="col-grey">{InstantaneousCount?.ENTRYTS ? moment.utc(InstantaneousCount?.ENTRYTS).add(-moment().utcOffset(), "m").fromNow('ss:hh:mm') : 0}</span></BootstrapTooltip>
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</>
				)}
			</div>


		</>
	);
}
// export const ConsumerArticlesMap = withScriptjs(withGoogleMap((props) => {
// 	const [jobDetail, setJobDetail] = useState({ activeMarker: {}, isOpen: false, Info: {} })
// 	const onMarkerClicked = (data, marker) => {
// 		console.log(data, marker);
// 		setJobDetail({
// 			activeMarker: { lat: data?.latLng?.lat(), lng: data?.latLng?.lng() },
// 			isOpen: true,
// 			Info: marker
// 		})
// 	}

// 	const onToggleOpen = () => {
// 		setJobDetail({ ...jobDetail, isOpen: false })
// 	}
// 	function getSecondsTodayBydate(ts) {
// 		var now = moment().add(-moment().utcOffset(), "m").format("DD/MM/YYYY HH:mm:ss");
// 		var then = moment.utc(ts).add(-moment().utcOffset(), "m").format("DD/MM/YYYY HH:mm:ss");
// 		return moment(now, "DD/MM/YYYY HH:mm:ss").diff(moment(then, "DD/MM/YYYY HH:mm:ss"), 'second')
// 	}

// 	return (<>
// 		<GoogleMap
// 			defaultZoom={5}
// 			defaultCenter={{ lat: props.curentpos.lat, lng: props.curentpos.lng }}
// 			onClick={(e) => { console.log("e.latLng.lat()", e.latLng.lat(), "e.latLng.lng()", e.latLng.lng()) }}
// 		>
// 			<><Marker
// 				defaultZoomOnClick={4}
// 				icon={{
// 					url: getSecondsTodayBydate(props.mapInfo.heartbeat_ts) < 36400 ? mapicon : mapRedicon,
// 					scaledSize: new window.google.maps.Size(25, 25)
// 				}}
// 				onMouseOver={(data) => onMarkerClicked(data, props?.mapInfo)}
// 				position={{ lat: parseFloat(props?.mapInfo?.latitude || ''), lng: parseFloat(props?.mapInfo?.longitude || '') }}
// 			/></>
// 		</GoogleMap>
// 		{jobDetail?.activeMarker?.lat && jobDetail.isOpen &&
// 			<InfoWindow position={{ lat: jobDetail?.activeMarker?.lat, lng: jobDetail?.activeMarker?.lng }} onCloseClick={onToggleOpen} options={{
// 				pixelOffset: new window.google.maps.Size(
// 					0, -15
// 				)
// 			}}>
// 				<div className="detailCard">
// 					<div className="d-flex main-div">
// 						<div className="d-inline-block">
// 							<div className="text-black name">{jobDetail?.Info?.pole_id}
// 							</div>
// 						</div>
// 					</div>
// 				</div>
// 			</InfoWindow>}
// 	</>
// 	);
// }))
