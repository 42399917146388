import React, { useMemo } from 'react'
import { AlertCircle, Edit, FileSpreadsheet, MoreHorizontal, RefreshCw } from 'lucide-react';
import { Card, CardHeader, CardContent, CardFooter } from '../../Common/ui/card';
import { Chip } from '../../Common/ui/chip';
import { Zap, Battery, Gauge, Activity, Radio } from 'lucide-react';
import { Button } from '../../Common/ui/button';
import { DropdownMenu, DropdownMenuCheckboxItem, DropdownMenuContent, DropdownMenuSeparator, DropdownMenuTrigger } from "../../Common/ui/dropdown-menu";
import moment from 'moment/moment';



const ZapMeter = ({ width = 20, height = 20 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" stroke="currentColor" fill="currentColor" width={width} height={height}>

      <g>
        <path stroke="currentColor" className="st0" d="M272.904,156.934c0-9.333-7.578-16.903-16.903-16.903c-9.333,0-16.903,7.57-16.903,16.903
          c0,9.326,7.57,16.903,16.903,16.903C265.326,173.837,272.904,166.26,272.904,156.934z"/>
        <path stroke="currentColor" className="st0" d="M73.548,322.484c-9.333,0-16.902,7.57-16.902,16.903c0,9.325,7.569,16.895,16.902,16.895
          c9.326,0,16.903-7.57,16.903-16.895C90.451,330.054,82.874,322.484,73.548,322.484z"/>
        <path stroke="currentColor" className="st0" d="M115.03,198.424c-6.59,6.604-6.59,17.298,0,23.903c6.604,6.597,17.306,6.597,23.911,0
          c6.596-6.605,6.596-17.298,0-23.903C132.336,191.819,121.635,191.819,115.03,198.424z"/>
        <path stroke="currentColor" className="st0" d="M373.06,198.424c-6.597,6.604-6.597,17.298,0,23.903c6.604,6.597,17.306,6.597,23.903,0
          c6.596-6.605,6.596-17.298,0-23.903C390.358,191.819,379.664,191.819,373.06,198.424z"/>
        <path stroke="currentColor" className="st0" d="M438.452,322.484c-9.333,0-16.902,7.57-16.902,16.903c0,9.325,7.57,16.895,16.902,16.895
          c9.326,0,16.896-7.57,16.896-16.895C455.348,330.054,447.778,322.484,438.452,322.484z"/>
        <path stroke="currentColor" className="st0" d="M437.016,159.906C390.738,113.598,326.645,84.914,256,84.922c-70.652-0.008-134.738,28.676-181.016,74.984
          C28.676,206.184-0.007,270.277,0,340.922c-0.007,30.204,5.26,59.221,14.889,86.156h29.877
          c-10.853-26.57-16.865-55.626-16.865-86.156c0.008-63.021,25.506-119.97,66.814-161.285c41.314-41.3,98.249-66.799,161.285-66.814
          c63.037,0.016,119.963,25.515,161.285,66.814c41.3,41.315,66.806,98.264,66.814,161.285c0,30.53-6.02,59.586-16.873,86.156h29.884
          C506.734,400.143,512,371.125,512,340.922C512.008,270.277,483.325,206.184,437.016,159.906z"/>
      </g>
      <path d="M256 200L226.724 245.494C225.289 247.765 224.571 248.901 224.607 249.796C224.639 250.57 224.969 251.306 225.525 251.853C226.156 252.474 227.352 252.474 229.744 252.474H256V297L285.276 251.506C286.711 249.235 287.429 248.099 287.393 247.204C287.361 246.43 287.031 245.694 286.475 245.147C285.844 244.526 284.648 244.526 282.256 244.526H256V200Z"
        stroke="currentColor"
        strokeWidth="24"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="scale(2) translate(-120 -70)" />
    </svg>
  );
}

const Voltage = (props) => (
  <svg
    width={20} height={20}
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 511.999 511.999"
    style={{
      enableBackground: "new 0 0 511.999 511.999",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <path
      style={{
        fill: props?.fillcolre ? props?.fillcolre : "#FFAD61",
      }}
      d="M13.978,420.162l221.306-354.21c9.567-15.311,31.865-15.311,41.431,0l221.305,354.21 c10.165,16.269-1.532,37.369-20.715,37.369H34.694C15.511,457.531,3.814,436.431,13.978,420.162z"
    />
    <polygon
      style={{
        fill: "#F2F2F2",
      }}
      transform="scale(1.1) translate(-20 -40)"
      points="310.048,277.875 263.584,277.875 289.68,183.594 234.974,183.594 198.89,313.958  245.355,313.958 219.26,408.238 "
    />
    <g>
      <path
        style={{
          fill: "#534741",
        }}
        d="M477.305,467.729H34.694c-12.771,0-24.092-6.668-30.283-17.837 c-6.191-11.169-5.848-24.303,0.919-35.133l221.305-354.21c6.369-10.193,17.346-16.278,29.364-16.278 c12.018,0,22.996,6.085,29.364,16.278l221.306,354.21c6.767,10.83,7.11,23.964,0.918,35.133 C501.396,461.061,490.076,467.729,477.305,467.729z M255.999,64.666c-5.012,0-9.411,2.438-12.067,6.69L22.628,425.566l0,0 c-2.781,4.45-2.922,9.847-0.377,14.437c2.544,4.589,7.197,7.33,12.445,7.33h442.61c5.248,0,9.899-2.74,12.444-7.33 c2.544-4.59,2.403-9.987-0.376-14.437L268.066,71.356C265.411,67.105,261.011,64.666,255.999,64.666z"
      />
      <path
        style={{
          fill: "#ffffff",

        }}
        transform="scale(1.1) translate(-20 -40)"
        d="M219.255,418.438c-1.46,0-2.937-0.314-4.331-0.969c-4.498-2.113-6.818-7.16-5.493-11.951 l22.52-81.362H198.89c-3.186,0-6.187-1.488-8.116-4.023c-1.928-2.534-2.562-5.825-1.712-8.896l36.082-130.363 c1.223-4.418,5.244-7.477,9.829-7.477h54.706c3.185,0,6.187,1.488,8.117,4.023c1.928,2.534,2.562,5.825,1.712,8.896l-22.52,81.361 h33.061c3.799,0,7.282,2.111,9.039,5.477c1.758,3.367,1.5,7.431-0.671,10.549l-90.789,130.363 C225.668,416.881,222.505,418.438,219.255,418.438z M212.295,303.759h33.061c3.186,0,6.187,1.488,8.116,4.023 c1.928,2.534,2.562,5.825,1.712,8.896l-10.166,36.731l45.501-65.335h-26.935c-3.185,0-6.187-1.488-8.116-4.023 c-1.928-2.534-2.562-5.825-1.712-8.896l22.52-81.361h-33.544L212.295,303.759z"
      />
      <path
        style={{
          fill: "none",
        }}
        d="M457.919,436.504h-74.445c-5.631,0-10.198-4.567-10.198-10.198s4.567-10.198,10.198-10.198h74.445 c5.631,0,10.198,4.567,10.198,10.198S463.551,436.504,457.919,436.504z"
      />
      <path
        style={{
          fill: "#none",
        }}
        d="M353.899,436.504h-8.158c-5.631,0-10.198-4.567-10.198-10.198s4.567-10.198,10.198-10.198h8.158 c5.631,0,10.198,4.567,10.198,10.198S359.532,436.504,353.899,436.504z"
      />
    </g>
  </svg>
);

const ZapIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <circle cx="12" cy="12" r="10" />
    <path d="M13 2L3 14h8l-1 8l10-12h-8l1-8z" width={16} /> {/* Lightning bolt */}
  </svg>
);

// const Chip = ({ children, variant = 'light', color, className = '' }) => (
//   <div className={`tw-text-center tw-flex-row tw-gap-1 ${className}`}>
//     {children}
//   </div>
// );

const MetricItem = ({ Icon, label, value, color, fillColor }) => (
  <Chip variant='light' color={color} className="tw-text-center tw-flex-row tw-gap-1">
    <Icon fillColor={fillColor} size={14} />
    <p className="tw-text-sm tw-mb-0">{label} :</p>
    <p className="tw-font-semibold tw-mb-0">{value}</p>
  </Chip>
);


export default function IOTCard({ data, onhandleTabChange,RefreshId,RefreshIndi,onRefreshBtn,onHandleTableModal }) {

  const isDataEmpty = useMemo(() => {
    if (data?.slave_type_id !== "SLTYID1727529848745") {
      return !data?.voltage_rn &&
        !data?.current_ir &&
        !data?.pf &&
        !data?.apparent_power &&
        !data?.frequency;
    } else {
      return !data?.voltage_rn &&
        !data?.voltage_yn &&
        !data?.voltage_bn &&
        !data?.current_ir &&
        !data?.current_iy &&
        !data?.current_ib &&
        !data?.pf &&
        !data?.apparent_power &&
        !data?.frequency;
    }
  }, [data]);

  console.log(data)


  const mainMetrics = useMemo(() => [
    {isCardView: true, Icon: Voltage, fillColor: "#b91c1ccc", label: data?.slave_type_id === "SLTYID1727529848745" ? 'VRN' : 'V',tooltipText: data?.slave_type_id === "SLTYID1727529848745" ? null : "Voltage", value: `${data?.voltage_rn!=null?Number(data?.voltage_rn)?.toFixed(2) : " - "} V`, color: "red" },
    { isCardView: true,Icon: Voltage, fillColor: "#b91c1ccc", label:  data?.slave_type_id === "SLTYID1727529848745" ? 'IR' : 'I', tooltipText: data?.slave_type_id === "SLTYID1727529848745" ? null : "Current", value: `${data?.current_ir?Number(data?.current_ir)?.toFixed(2) : " - "} A`, color: "red" },
    {isCardView: data?.slave_type_id === "SLTYID1727529848745", Icon: Voltage, label: 'VYN', value: `${data?.voltage_yn?Number(data?.voltage_yn)?.toFixed(2) : " - "} V`, color: "yellow" },
    { isCardView: data?.slave_type_id === "SLTYID1727529848745",Icon: Voltage, label: 'IY', value: `${data?.current_iy?Number(data?.current_iy)?.toFixed(2) : " - "} A`, color: "yellow" },
    { isCardView: data?.slave_type_id === "SLTYID1727529848745",Icon: Voltage, fillColor: "#4338cacc", label: 'VBN', value: `${data?.voltage_bn?Number(data?.voltage_bn)?.toFixed(2) : " - "} V`, color: "indigo" },
    { isCardView: data?.slave_type_id === "SLTYID1727529848745",Icon: Voltage, fillColor: "#4338cacc", label: 'IB', value: `${data?.current_ib?Number(data?.current_ib)?.toFixed(2) : " - "} A`, color: "indigo" },
  ], [data]);

  const metrics = useMemo(() => [
    { Icon: Zap, value: data?.active_power!=null?Number(data?.active_power)?.toFixed(2):'-', unit: "KW", label: "" },
    { Icon: ZapMeter, value:data?.apparent_power!=null? Number(data?.apparent_power)?.toFixed(2):'-', unit: "KVA", label: "" },
    { Icon: Gauge, value: data?.pf!=null?Number(data?.pf)?.toFixed(2):'', unit: "PF", label: "" },
    // { Icon: Activity, value: data?.total_energy!=null?Number(data?.total_energy)?.toFixed(2):'-', unit: "KWH", label: "" },
    { Icon: Radio, value: data?.frequency!=null?Number(data?.frequency)?.toFixed(2):'-', unit: "Hz", label: "" },
  ], [data]);

  const lastEntryTime = useMemo(() => {
    return data?.last_entry
      ? moment(data.last_entry).add(-moment().utcOffset(), "m").fromNow('ss:hh:mm')
      : "";
  }, [data?.last_entry]);



  return (
    <Card className={`tw-w-full !tw-shadow-custom tw-relative`}>
      <CardHeader className="tw-flex tw-justify-between tw-items-start tw-flex-row tw-border-b tw-p-2 tw-px-6">
        <div>
          <h2 className="tw-text-lg tw-font-semibold tw-mb-0">{data?.card_name}</h2>
          <p className="tw-text-sm tw-text-gray-500 tw-mb-0">{data?.card_description}</p>
        </div>
        <div className="tw-flex tw-space-x-2">
          <Button variant='indigo' size='icon' disabled={RefreshId == data.unique_id && RefreshIndi === true?true:false} onClick={()=>onRefreshBtn(data.unique_id)}><RefreshCw size={16} className={RefreshId == data.unique_id && RefreshIndi === true?'tw-animate-spin':''}/></Button>
          <DropdownMenu dis >
            <DropdownMenuTrigger asChild>
              <Button variant='indigo' size='icon'><MoreHorizontal size={16} /></Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="tw-px-0 tw-py-2 tw-w-[50px] -tw-translate-x-2 tw-bg-white">
              <DropdownMenuCheckboxItem onClick={() => onhandleTabChange(data?.unique_id)} className='tw-cursor-pointer tw-flex tw-gap-x-1 tw-items-center tw-text-[13px] tw-leading-3 !tw-px-5 hover:tw-text-indigo-500 hover:tw-bg-indigo-100 tw-duration-200'>
                <Edit size={16} /> Edit
              </DropdownMenuCheckboxItem>
              <DropdownMenuCheckboxItem onClick={() => onHandleTableModal(data)} className='tw-cursor-pointer tw-flex tw-gap-x-1 tw-items-center tw-text-[13px] tw-leading-3 !tw-px-5 tw-py-2 hover:tw-text-indigo-500 hover:tw-bg-indigo-50 tw-duration-200'>
                <FileSpreadsheet  size={16} /> Table Info
              </DropdownMenuCheckboxItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </CardHeader>
      {
        isDataEmpty && <div className="tw-flex tw-items-center tw-justify-center tw-z-10 tw-text-gray-500 tw-absolute tw-w-full tw-h-4/6">
          <div className="tw-text-center">
            <AlertCircle size={40} className="tw-mx-auto tw-mb-2" />
            <p className="tw-mb-0 fs-4 tw-text-gray-600  tw-capitalize !tw-font-bold">Waiting for process</p>
          </div>
        </div>
      }
      <CardContent className={`tw-px-3 tw-pb-1 tw-min-h-[240px] ${isDataEmpty && 'tw-opacity-30'}`}>


        <div className="tw-grid tw-grid-cols-2 tw-gap-4 tw-gap-y-2 tw-my-4">
        {mainMetrics.map((metric, index) => (
            metric?.isCardView ? <MetricItem key={index} {...metric} /> : null
          ))}
        </div>
        <div className="tw-grid tw-grid-cols-2 tw-gap-2">
          {metrics.map((metric, index) => (
            <CustomBadge key={index} color="blue" className='tw-flex tw-gap-1 tw-items-center tw-w-auto'>
              <metric.Icon size={14} />
              <p className="tw-font-semibold tw-mb-0 tw-text-sm">{metric.value || " - "}</p>
              <p className="tw-mb-0 tw-text-sm">{metric.unit}</p>
            </CustomBadge>
          ))}
        </div>

      </CardContent>

        <CardFooter className={`tw-flex tw-justify-between tw-items-center  tw-pb-2 ${isDataEmpty && 'tw-opacity-30'}`}>
          <p className="tw-text-xs tw-text-gray-500 tw-mb-0">{lastEntryTime}</p>
          <div className="tw-flex tw-space-x-2">
            {/* <div className="tw-bg-green-100 tw-text-green-800 tw-text-xs tw-font-medium tw-px-2.5 tw-py-0.5 tw-rounded">
              Sub Card : - / -
            </div> */}
            <div className="tw-bg-blue-100 tw-text-blue-800 tw-text-xs tw-font-medium tw-px-2.5 tw-py-0.5 tw-rounded">
              Slaves : {JSON.parse(data?.data_found_slave||'[]').length} / {JSON.parse(data?.addition_slave_list||'[]').length+JSON.parse(data?.substraction_slave_list||'[]').length}
            </div>
          </div>
        </CardFooter>

    </Card>
  );

}


const colorVariants = {
  green: 'tw-bg-green-50 tw-text-green-800',
  blue: 'tw-bg-blue-50 tw-text-blue-800',
  red: 'tw-bg-red-50 tw-text-red-800',
  yellow: 'tw-bg-yellow-50 tw-text-yellow-800',
  purple: 'tw-bg-purple-50 tw-text-purple-800',
  pink: 'tw-bg-pink-50 tw-text-pink-800',
  indigo: 'tw-bg-indigo-50 tw-text-indigo-800',
  gray: 'tw-bg-gray-50 tw-text-gray-800',
  light: 'tw-bg-gray-50 tw-text-gray-600',
};

const CustomBadge = ({ children, color = 'light', className = '' }) => {
  const colorClasses = colorVariants[color] || colorVariants.light;

  return (
    <div className={`tw-text-xs tw-font-medium tw-px-2.5 tw-py-1 tw-rounded ${colorClasses} ${className}`}>
      {children}
    </div>
  );
};